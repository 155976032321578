<template>
    <div @contextmenu.prevent="showContextMenu($event)" >
        <!-- <small>({{ traderId }})</small> -->
        <v-menu v-model="contextMenuOpen" :position-x="contextMenuX" :position-y="contextMenuY">
            <v-list dense>
                <v-list-item dense @click="dialog.add_traders_to_portfolio = true">Add To Portfolio</v-list-item>
                <v-list-item dense @click="go_to_page()">Go To Page </v-list-item>
            </v-list>
        </v-menu>

        <div >
            <div style="display:flex">
                <v-icon small class="ma-0">mdi-wallet</v-icon>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <div  v-bind="attrs" v-on="on" class="ma-1" > 
                            <div @click="short_dialog = true">
                                {{ disp_human_name() }}  
                                <!-- {{traderId.toString(34)}}  -->
                            </div>
                        </div>
                    </template>
                    <div >
                        id: {{traderId.toString(10).match(/.{1,3}/g).join('-')}}  <br>
                        addrs: {{`0x${(traderId**2).toString(16).slice(2,5)}...${(traderId**4).toString(16).slice(5,9)}`}}
                    </div>
                </v-tooltip>
            </div>
        </div>
        
        <!-- popup Title  -->
        <v-dialog v-model="short_dialog"  max-width="70vw" >
            <v-sheet rounded="sm">
                
                <trader-card-view :traderId="traderId"> </trader-card-view>
            </v-sheet>
        </v-dialog>
        <v-dialog v-model="dialog.add_traders_to_portfolio"  max-width="70vw" >
            <v-sheet rounded="sm">
                <trader-static-list-add-ids @added="dialog.add_traders_to_portfolio = false " :selectedIds="[traderId]"></trader-static-list-add-ids>
            </v-sheet>
        </v-dialog>

    </div>
</template>
<script>
// Begin Component Imports
import TraderStaticListAddIds from './static_lists/trader-static-list-add-ids.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
import names from '@/assets/names.json'
import TraderCardView from './trader-card-view.vue'
export default {
    name: 'trader-link-to-page',
    components:{
        TraderStaticListAddIds,
        TraderCardView
    },
    emits:[
    ],
    data(){return {
            contextMenuOpen: false,
            contextMenuX: 0,
            contextMenuY: 0,
            selectedOption: null,
            short_dialog:false,
            dialog:{
                add_traders_to_portfolio:false
            }
        }
    },
    props: { 
        traderId:{type:[Number,String],default:0},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
        disp_human_name(){
            // return 's'
            const tid = parseInt(this.traderId)
            const nrnames = names.length
            const first_index = tid%nrnames
            const second_index = (tid + 1000 ) %nrnames
            return `${names[first_index]} ${names[second_index]}`
        },
        showContextMenu(event) {
            event.preventDefault();
            this.contextMenuX = event.clientX;
            this.contextMenuY = event.clientY;
            this.contextMenuOpen = true;
        },
        handleMenuItemClick(option) {
            this.selectedOption = option;
            this.contextMenuOpen = false;
        },
        go_to_page(){
            console.log("CHanging window location");
            window.location =  `/trader/${this.traderId}`
        }
    },
    computed:{
        ...mapState([]),
        disp_nr(){
            if (!this.traderId){return '-'}
            let tid_str = `${this.traderId}`

            if(tid_str.length == 2){tid_str = '0000' +tid_str }
            if(tid_str.length == 3){tid_str = '000' +tid_str }
            if(tid_str.length == 4){tid_str = '00' +tid_str }
            if(tid_str.length == 5){tid_str = '0' +tid_str }
            if(tid_str.length == 6){return `${tid_str.slice(0,3)}-${tid_str.slice(3,6)}`}
        }
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
small{
    color:gray;
}
a{
    text-decoration: none;
    color:white;
}
.hex-rep-id{
    display:flex;
    font-family: 'Courier New', Courier, monospace;
    //text-transform: uppercase;
    .gray{
        color:gray;
        font-family: 'Courier New', Courier, monospace;
    }
}
</style>