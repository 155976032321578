<template>
    <div>


        <div v-if="data">
            <div v-if="data.links.verified">
                <v-expansion-panels v-if="data">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            ({{data.links.links.length}}) Links
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <div v-if="data.links.links.length != 0">
                                <div class="clink" v-for="link in data.links.links" :key="link">
                                    <a :href="link" target="blank"  > {{link}}</a>
                                </div>
                            </div>
                            <div v-else>
                                No links found in source
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            ({{data.links.comments.multiline.length + data.links.comments.line.length}}) Comments
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-tabs centered color="white" >
                                    <v-tab>({{data.links.comments.multiline.length}}) multiline</v-tab>
                                    <v-tab> ({{data.links.comments.line.length}}) line </v-tab>
                                    <v-tab-item>
                                        <div class="comment" v-for="mlc in data.links.comments.multiline" :key="mlc">
                                            {{mlc}}
                                        </div>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <div class="comment" v-for="lc in data.links.comments.line" :key="lc">
                                            {{lc}}
                                        </div>
                                    </v-tab-item>
                            </v-tabs>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            ({{data.links.sources.length}}) Source Code
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <pre  class="contract_source" v-for="source in data.links.sources" :key="source">
                                {{source}}

                            </pre>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <div v-else style="display:flex;     flex-direction: column; align-items: center;">
                Contract was not verified, last checked:
                <time-display :time="data.links.contract_checked_at"></time-display>
                <v-btn class="ma-0"  elevation="2" small  @click="do_get_contract_source_code_and_meta(true)" > Force fetch recheck</v-btn>

            </div>

        </div>
        <v-btn v-else class="ma-0"  elevation="2" small   @click="do_get_contract_source_code_and_meta()" >Fetch contract data</v-btn>

    
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import timeDisplay from './time-display.vue'
export default 
 {
    name: 'pair-verified-contract-info',
    components:{
        timeDisplay
    },
    emits:[
    ],
    data(){return {
        data:undefined,
        sections: ['links','comments','sources']
        }
    },
    props: { 
        pairId:{type:Number},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['get_contract_source_code_and_meta']),
        do_get_contract_source_code_and_meta(forced){
            this.get_contract_source_code_and_meta({qs:{pair_id:this.pairId , 'force_fetch':forced},data:{}}).then((r)=>{
                this.data = r.data
            })
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
        pairId(nv,ov){
            this.data = undefined
        }
    },
}
</script>
<style scoped lang="scss">
.contract_source{
    font-size: 0.7em;
    overflow: scroll;
    margin:0.5em;
}
.comment{
    background: #ffffff12;
    padding: 0.5em;
    border-radius: 0.5em;
    margin: 0.2em;
}

.clink{
        background: #ffffff7b;
    padding: 0.3em;
    border-radius: 0.5em;
    margin: 0.4em;
    text-align: center;
}
</style>