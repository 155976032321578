<template>
    <div>
        {{pair_data.length}} tokens in this list.
        <table>
            <tr></tr>
            <tr v-for="p in pair_data" :key="p.id">
                <td>
                    <pair-link-to-page :pair="p.pair"></pair-link-to-page>
                </td>
            </tr>
        </table>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import PairLinkToPage from '../pair-link-to-page.vue'
export default {
    name: 'my-list-preview',
    components:{
        PairLinkToPage
    },
    emits:[
    ],
    data(){return {
        pair_data : [],
        is_loading:false,
        }
    },
    props: { 
        list_id:{type:Number,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_get_standard_listings()
    },
    methods:{
        ...mapActions(['token_list_actions']),
        do_get_standard_listings(){
            this.token_list_actions({qs:{action:'get_listings'},data:{list_id:this.list_id, list_type:'tokens.static'}}).then((r)=>{ 
                this.is_loading = false
                this.pair_data = r.data.listings
                })
        },
    },
    computed:{
        ...mapState([]),
    },
    watch:{
        list_id(nv, ov){
            this.do_get_standard_listings()
        }
    },
}
</script>
<style scoped lang="scss">
</style>