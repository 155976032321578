<template>
    <div class="timefield" >
        <!-- <span v-if="mode=='timedelta'"> Δ </span> -->
        {{calculated_time}}
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'time-display',
    components:{
    },
    emits:[
    ],
    data(){return {
        calculated_time:'-',
        }
    },
    props: { 
        mode:{type:String,default:'time'}, // time, timedelta, timesince
        time:{type:Number,default:Number},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.calculate_time()
        if(this.mode == 'timesince'){setInterval(this.calculate_time, 1000)}
    },
    methods:{
        ...mapActions([]),
        formatTimeDelta(milliseconds) {
            milliseconds = Math.abs(milliseconds);

            const days = Math.floor(milliseconds / (24 * 60 * 60 * 1000));
            const hours = Math.floor((milliseconds % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
            const minutes = Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000));
            const seconds = Math.floor((milliseconds % (60 * 1000)) / 1000);
            if(milliseconds < 60000){ return `${seconds} sec`}

            let result = '';
          
            if (days > 0) { result += `${days} day${days > 1 ? 's' : ''} `; }
            result += `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

            return result;
        },
        formatDate(date) {
            
            const now = new Date();
            const currentYear = now.getFullYear()
            const isToday = date.getDate() === now.getDate() &&
                            date.getMonth() === now.getMonth() &&
                            date.getFullYear() === currentYear;

            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = String(date.getFullYear()).slice(-2);
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');

            var dayOfWeek = date.getDay();
            var daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            var abbreviatedDayName = daysOfWeek[dayOfWeek];

            var monthIndex = date.getMonth();
            var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var abbreviatedMonthName = months[monthIndex];

            var yearString = ''
            if ( date.getFullYear() !== currentYear){ 
                var yearString = ', ' + String(date.getFullYear())
            }
            if (isToday) {
                return `${hours}:${minutes}`;
            } else {
                return `${abbreviatedDayName} ${abbreviatedMonthName} ${day} ${hours}:${minutes}${yearString}`;
            }
        },

        calculate_time(){
            if (this.time==0 ){ return  ' - '}
            if (this.mode == 'time'){ this.calculated_time = this.formatDate( new Date(this.time*1000)); return }
            if (this.mode == 'timesince'){  this.calculated_time = this.formatTimeDelta(((new Date() *0.001)- this.time ) *1000 ); return  }
            if (this.mode == 'timedelta'){  this.calculated_time = this.formatTimeDelta((this.time ) *1000 ); return  }

        }

    },
    computed:{
        ...mapState([]),

    },
    watch:{
        time(nv,ov){this.calculate_time()}
    },
}
</script>
<style scoped lang="scss">
.timefield{
     font-family: monospace;

}
</style>