<template>
    <div @contextmenu.prevent="showContextMenu($event)"  class="pair-link-wrap">

            <v-menu v-model="contextMenuOpen" :position-x="contextMenuX" :position-y="contextMenuY">
                <v-list dense>
                    <v-list-item dense @click="go_to_buy_pair_page">Open Page </v-list-item>
                    <v-list-item dense @click="add_to_portfolio_dialog=true">Add To Portfolio</v-list-item>
                    <v-list-item dense @click="go_to_buy_token_swap_page">Buy</v-list-item>
                    <v-list-item dense>
                        <external-link-etherscan  :hash="pair.currency_address" type="address" ></external-link-etherscan>
                        Open Etherscan
                    </v-list-item>
                    <v-list-item dense >
                        <external-link-dextools :tokenAddress="pair.currency_address">  </external-link-dextools> Open Dextools
                    </v-list-item>
                </v-list>
            </v-menu>
            
            <!-- <external-link-dextools :tokenAddress="pair.currency_address"></external-link-dextools> -->
            <!-- <router-link :to="`/pair/${pair.currency_address}`" class="ml-1">
                
                {{pair.symbol.slice(0,20)}} 
                <sub> 
                    {{pair.name.slice(0,20)}} <span v-if="pair.name.length > 30">...</span>
                </sub> 
            </router-link> -->
            <v-icon class="ma-1" @click="dialog=true" color="orange" >mdi-minus-circle-multiple</v-icon>
            <div class="token-name-symbol" @click="dialog=true">
                <div class="token-symbol"  v-if="pair.symbol"> {{pair.symbol.slice(0,20)}}  </div>
                <div class="token-symbol" v-else> ? </div>
                <div class="token-name" v-if="pair.name">  {{pair.name.slice(0,20)}} <span v-if="pair.name.length > 30">...</span> </div> 
                <div class="token-name" v-else> ? </div>
            </div>
            <!-- {{pair}} -->
            
            <v-dialog v-model="dialog"  max-width="90vw" >
                <v-sheet v-if="dialog" class="popup-dialog">
                    <view-pair :vlines="vlines" :address="pair.id"></view-pair>
                </v-sheet>
            </v-dialog>

            <!-- popup Title  -->
            <v-dialog v-model="add_to_portfolio_dialog"  max-width="70vw" >
                <v-sheet rounded="sm">
                    <my-list-add-pair @added="add_to_portfolio_dialog=false" :selected_pairs="[pair]"></my-list-add-pair>
                </v-sheet>
            </v-dialog>
  
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import ExternalLinkDextools from './external-link-dextools.vue'
import ExternalLinkEtherscan from './external-link-etherscan.vue'
import MyListAddPair from './static_lists/my-list-add-pair.vue'
import ViewPair from './view-pair.vue'
export default {
    name: 'pair-link-to-page',
    components:{
        ExternalLinkDextools,
        ViewPair,
        MyListAddPair,
        ExternalLinkEtherscan
    },
    emits:[
    ],
    data(){return {
            dialog:false,
            add_to_portfolio_dialog:false,
            contextMenuOpen: false,
            contextMenuX: 0,
            contextMenuY: 0,
            selectedOption: null,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        pair:{type:Object,default:Object},
        vlines:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),

        showContextMenu(event) {
            event.preventDefault();
            this.contextMenuX = event.clientX;
            this.contextMenuY = event.clientY;
            this.contextMenuOpen = true;
        },
        handleMenuItemClick(option) {
            this.selectedOption = option;
            this.contextMenuOpen = false;
        },
        go_to_buy_token_swap_page(){
            window.location = `/swap?token_out=${this.pair.id}`
        },
        go_to_buy_pair_page(){
            window.location = `/pair/${this.pair.id}`
        },

    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.pair-link-wrap{
    display:flex;
}
a{
    text-decoration: none;
    color:white!important;
}
.popup-dialog{
    overflow:scroll;
}

.token-name{
    font-size: 0.7em;
    padding-left: 3px;
    color: #ffffff9e;
    margin-top: 7px;
}
.token-symbol{

}
.token-name-symbol{
    display:flex;
    align-items: center;
}
</style>