import { render, staticRenderFns } from "./traders-group-table.vue?vue&type=template&id=6d77d905&scoped=true&"
import script from "./traders-group-table.vue?vue&type=script&lang=js&"
export * from "./traders-group-table.vue?vue&type=script&lang=js&"
import style0 from "./traders-group-table.vue?vue&type=style&index=0&id=6d77d905&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d77d905",
  null
  
)

export default component.exports