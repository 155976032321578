import { render, staticRenderFns } from "./pair-ml-labs.vue?vue&type=template&id=7950d638&scoped=true&"
import script from "./pair-ml-labs.vue?vue&type=script&lang=js&"
export * from "./pair-ml-labs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7950d638",
  null
  
)

export default component.exports