<template>
    <div>
        <div v-if="selected_points.first">
            <div v-if="selected_points.second">
                The Token went {{ (selected_points.second.y  / selected_points.first.y).toFixed(1)}} x <br>
                in {{ formatTimeDelta(selected_points.second.x  - selected_points.first.x)}} 
                <v-btn class="ma-0"  elevation="2" small block @click="set_focus_chart_to_points" >set focus to area</v-btn>
            </div>
            <div v-else>
                Now click on a second point to compare it with
            </div>
        </div>
        <div v-else>  </div>
        <div style=" width:100%;height:40vh; ">
            <canvas id="priceChart" ></canvas>
        </div>
        <!-- <pre>
            {{selected_points}}
        </pre> -->

        
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import {Chart, registerables} from 'chart.js'

Chart.register(...registerables);
export default {
    name: 'pair-price-chart-large',
    components:{
    },
    emits:[
    ],
    data(){return {
        charts:{},
        selected_points:{
            first:undefined,
            second:undefined,
        }
        }
    },
    props: { 
        //propName:{type:String,default:''},
        priceList:{type:Array,default:Array},
        verticalLines:{type:Array,default:Array},
        vlines:{type:Array,default:Array},
    },
    mounted(){
  
        this.init_chart('priceChart')

    
    },
    methods:{
        ...mapActions([]),
         formatTimestamp(timestamp) {
            const date = new Date(timestamp*1000);
            const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            return date.toLocaleDateString(undefined, options);
            },
             formatTimeDelta(seconds) {
                    const days = Math.floor(seconds / (60 * 60 * 24));
                    const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
                    const minutes = Math.floor((seconds % (60 * 60)) / 60);
                    const remainingSeconds = seconds % 60;

                    let formattedTime = "";

                    if (days > 0) {
                        formattedTime += days + "days ";
                    }

                    if (hours > 0 || formattedTime !== "") {
                        formattedTime += hours + "hr ";
                    }

                    if (minutes > 0 || formattedTime !== "") {
                        formattedTime += minutes + "min ";
                    }

                    formattedTime += remainingSeconds + "s";

                    return formattedTime;
            },

            handleDataPointClick(value, fp){
                console.log(value, fp,"DATA CLICL POINT");
                var point = this.priceList[fp.index];

                if (this.selected_points.first == undefined){
                    this.selected_points.first = point
                }
                else if (this.selected_points.second == undefined){
                    this.selected_points.second = point
      
                }
                else{
                    this.selected_points.first = point
                    this.selected_points.second = undefined
                }
            },
        init_chart(chartName){

            try{this.charts[chartName].destroy()}catch{}
            const ctx = document.getElementById(chartName);
            console.log(this.priceList);
            const max_price = Math.max(...this.priceList.map(p => p.y))

            console.log("Max price", max_price);
            console.log("CONTEXT", ctx);


            const v_lines = this.vlines.map(xl =>{
                return {
                        label: xl.name,
                        data: [{x:xl.time, y:0},{x:xl.time, y:max_price}],
                        borderColor: xl.color,
                        fill: false,
                        tension: 0.1,
                        pointRadius: 0,
                }
            })

            this.charts[chartName] = new Chart(ctx, {
                type: 'line',
                data: {                    
                    datasets: [
                    {
                        label: 'Price of 1% ts',
                        data: this.priceList,
                        borderColor: '#d1ffff',
                        // pointBackgroundColor: 'rgba(255, 0, 0, 1)', // Point color
                        pointBorderColor: 'rgba(255, 255, 255, 0.1)', // Point border color
                        pointRadius: 5, // Point radius

                        fill: false,
                        tension: 0.3,
                        borderWidth:2,
                    }, ...v_lines
        
                ]},
                options: {               
          maintainAspectRatio: false,
        responsive: true,
                    scales: {
                    x: {
      
                        type: 'linear',
                        title: { display: true, text: 'Time', },
                        ticks: { callback:  (value, index, values) =>{ return this.formatTimestamp(value); }, },
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                        display: true,
                        text: 'Standardized price',
                        },
                    },
                    }, }
            });

            ctx.addEventListener('click', (event)=> {
                const points = this.charts[chartName].getElementsAtEventForMode(event, 'nearest', { intersect: true });

                if (points.length > 0) {
                    const firstPoint = points[0];
                    const datasetIndex = firstPoint.datasetIndex;
                    const dataIndex = firstPoint.index;
                    const value = this.charts[chartName] .data.datasets[datasetIndex].data[dataIndex];
                    this.handleDataPointClick(value, firstPoint);
                }
            });


        },
        set_focus_chart_to_points(){
            this.priceList = this.priceList.filter(p =>{
               return ( p.x >= this.selected_points.first.x) & (p.x <= this.selected_points.second.x)
            })
            this.init_chart('priceChart')
        },


    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>