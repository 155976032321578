<template>
    <div>
        <a target="blank" :href="`https://etherscan.io/${type}/${hash}`"> 
            <img class="mt-2" :height="height" :src="`${db_url}static/img/etherscan_logo.svg`" alt="" srcset="">
        </a>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'external-link-etherscan',
    components:{
    },
    emits:[
    ],
    data(){return {
  
        }
    },
    props: { 
        hash:{type:String,default:''},
        type:{type:String,default:'tx'},
        height:{type:String,default:'20px'},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
 
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState(['db_url']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
img{
        filter: invert(1);
}
</style>