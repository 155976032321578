<template>
    <div>
        <!-- {{traderIds}} -->
        <v-container fluid>
            <v-row>
                <v-col sm="12" md="12">
                    <!-- <chartjs-trader-scores-scatter-plot :data="standardScores"></chartjs-trader-scores-scatter-plot> -->
                    <chartjs-barchart :count="for_chart.count" :data="for_chart.datasets" :labels="for_chart.labels"></chartjs-barchart>
                    <v-btn class="ma-1"  elevation="2" small  @click="do_trader_ensure_complete_trades(true)" > 
                        check trades and scores   
                        <span v-if="loading.trader_trades_check"> [Loading Trade Check] </span>  
                        <span v-if="loading.trader_standard_scores"> [Loading Scores]</span> 
                    </v-btn>
                    <!-- <v-btn class="ma-1"  elevation="2" small  @click="do_trader_calculate_get_standard_scores(false)" > 
                            get scores 
                        <span v-if="loading.trader_standard_scores"> [Loading Scores]</span> 
                    </v-btn> -->
                    {{msg}}
                </v-col>
            </v-row>

            <v-row v-if="standardScores">

                <v-col cols="12" md="12">
                    <!-- <div style="font-size:0.1em">

                    STANDARD SCORES FROM TRADER SELECTOR ON SC{{standardScores}}
                    </div> -->
                    <v-tabs centered color="white" >
                            <v-tab>Buyer</v-tab>
                            <v-tab>Corralations</v-tab>
                            <v-tab-item>
                                <v-btn class="ma-0"  elevation="2" small @click="$emit('selected_ids_changed',selected_ids)" >emit ({{selected_ids.length}}) selected ids</v-btn>
                                <table v-if="standardScores">
                                    <tr v-for="(sc,tid) in standardScores" :key="tid">
                                        <td> {{tid}}</td>
                                        <td> 
                                            <!-- {{sc.scores}} -->
                                            <table>
                                                <tr v-for=" s in sc.scores" :key="s.score_name">
                                                    <td>
                                                        {{s.score_name}}
                                                    </td>
                                                    <td>
                                                        <!-- {{s}} -->
                                                        <table>
                                                            <tr v-for="(m,mn) in s.score" :key="`${s.score_name}.${mn}`" >
                                                                <td> 
                                                                    {{mn.replaceAll('_',' ')}}
                                                                    <v-icon @click="bar_chart_add(`${s.score_name}.${mn}`)" class="ma-0">mdi-chart-bar</v-icon>
                                                                    </td>
                                                                <td> {{m}}</td>
                                                            </tr>
                                                        </table>
                                                    </td>

                                                </tr>
                                            </table>
                                        </td>

                                    </tr>
                                </table>
                                <table v-if="buyer_scores">
                                    <tr>
                                        <th></th>
                                        <th>trader</th>
                                        <th>tokens</th>
                                        <th>trades</th>
                                        <th>score</th>
                                        <th>cv</th>
                                        <th>skw</th>
                                    </tr>
                                    <tr v-for="trader_id in buyer_tids_sorted" :key="trader_id" :style="selected_ids.includes(trader_id) ? 'background: #6130715e': ''">
                                        <td @click="trader_toggle_select(trader_id)">
                                            +
                                        </td>
                                        <td> 
                                            <trader-link-to-page :traderId="trader_id"></trader-link-to-page>
                                        </td>
                                        <td :style="buyer_scores[trader_id].token_count < 8 ? 'color:gray' :'' " >{{buyer_scores[trader_id].token_count}}</td>
                                        <td :style="buyer_scores[trader_id].token_count < 8 ? 'color:gray' :'' " >{{buyer_scores[trader_id].trade_count}}</td>
                                        <td :style="buyer_scores[trader_id].token_count < 8 ? 'color:gray' :'' " @click="trader_toggle_select(trader_id)">
                                            <div v-if="buyer_scores[trader_id].avg">
                                                {{buyer_scores[trader_id].avg.toFixed(2)}} x 
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="buyer_scores[trader_id].avg">
                                                <div :style="(buyer_scores[trader_id].std / buyer_scores[trader_id].avg) > 3 ? 'color:red' :'' ">

                                                {{((buyer_scores[trader_id].std / buyer_scores[trader_id].avg)*100).toFixed(1)}} % 
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="buyer_scores[trader_id].skw">
                                                {{buyer_scores[trader_id].skw.toFixed(2)}}
                                            </div>
                                        </td>
                                    </tr>

                                </table>

                            </v-tab-item>
                            <v-tab-item>
                                <div class="corr-matrix">

                                    <table v-if="mutual_investment_dependence"> 
                                        <tr>
                                            <th></th>
                                            <th v-for="(d, tid) in mutual_investment_dependence" :key="tid"> {{tid}}</th>
                                        </tr>
                                        <tr v-for="(d, tid) in mutual_investment_dependence" :key="tid" >
                                            <th> {{tid}} </th>
                                            <td v-for="(_, tid2) in mutual_investment_dependence" :key="tid2" :style="`background-color:rgba(255,0,0,${mutual_investment_dependence[tid][tid2]})`">
                                                {{mutual_investment_dependence[tid][tid2].toFixed(2)}}
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                            </v-tab-item>
                    </v-tabs>
                    
                </v-col>
                <v-col cols="12" md="12">
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import ChartjsBarchart from './chartjs-barchart.vue'
import ChartjsTraderScoresScatterPlot from './chartjs-trader-scores-scatter-plot.vue'
import TraderLinkToPage from './trader-link-to-page.vue'
export default {
    name: 'trader-selector-on-standard-scores',
    components:{
        TraderLinkToPage,
        ChartjsBarchart,
        ChartjsTraderScoresScatterPlot
    },
    emits:[
        'selected_ids_changed'
    ],
    data(){return {
        buyer_scores:undefined,
        mutual_investment_dependence:undefined,
        selected_ids:[],
        loading:{
            trader_trades_check:false,
            trader_standard_scores: false,
        },
        trader_standard_scores: undefined,
        standardScores:undefined,
        msg:'',
        for_chart: {
            count:0,
            labels:[],
            datasets:[],
        }

        }
    },
    props: { 
        //propName:{type:String,default:''},
        // standardScores:{type:Object,default:Object},
        traderIds:{type:Array,default:Array},
    },
    mounted(){
        this.calc_derivatives()
    
    },
    methods:{
        ...mapActions(['trader_calculate_get_standard_scores','trader_ensure_complete_trades']),

        bar_chart_add(score_metric){
            var labels = []
            var dataset = {'label':score_metric,data:[],backgroundColor:'white'}
            for (const tid in this.standardScores) {
                if (Object.hasOwnProperty.call(this.standardScores, tid)) {
                    const scores = this.standardScores[tid].scores;
                    // if (! labels.includes(tid)) { labels.push(tid) }
                    for (let si = 0; si < scores.length; si++) {
                        const score_name = scores[si].score_name;
                        if(score_metric.includes(score_name)){

                            for (const metric_name in scores[si].score) {
                                if (Object.hasOwnProperty.call(scores[si].score, metric_name)) {
                                    const metric_val = scores[si].score[metric_name];
                                    if(score_metric.includes(metric_name)){
                                        labels.push(tid)
                                        dataset.data.push(metric_val)
                                    }
                                    
                                }
                            }


                        }
                    }
                    
                }
            }

            console.log(labels, dataset , "LABEL DATASET");
            this.for_chart.labels = labels 
            this.for_chart.datasets = [dataset]
            this.for_chart.count += 1

        },
        get_score_from_scores(score_domain, scores){
            for (let i = 0; i < scores.length; i++) {
                const score = scores[i];
                if (score.score_name == score_domain){
                    return score
                }
            }

        },
        calc_derivatives(){
           
            let bsg = {}
            let trader_tinvestments = {}
            let errored_tids = []
            for (const tid in this.standardScores) {
                if (Object.hasOwnProperty.call(this.standardScores, tid)) {
                    try{
                        const scores = this.standardScores[tid].scores;
                        const buyer_score = this.get_score_from_scores('score_buy_signal', scores)
                        const complete_score = this.get_score_from_scores('score_complete_pair_trades', scores)
                        
                        let token_count = complete_score.score.total_tokens_invested
                        let trade_count = buyer_score.index
    
                        if (!token_count){ token_count = 0 }
                        if (!trade_count){ trade_count = 0 }
                        console.log(buyer_score);
    
                        bsg[tid] = {
                            token_count:token_count, 
                            trade_count:trade_count ,
                            avg:buyer_score.score.max_price_multiplier, 
                            std:buyer_score.score_std.max_price_multiplier, 
                            skw: buyer_score.score_skew.max_price_multiplier}
                        trader_tinvestments[tid] = Object.keys(complete_score.calc_sheet.score_per_pair.time_epoch)
                        //  If the skew is positive, some instances are significantly higher , if it is negative, some are significantly smaller, if it is 0 then the std should be small aswel. 

                    }catch{
                        errored_tids.push(tid)
                    }
                
                }
            }
            let matrix = {}
            for (let key1 in trader_tinvestments) {
                matrix[key1] = {};
                for (let key2 in trader_tinvestments) {
                    const commonLetters = trader_tinvestments[key1].filter(token_id => trader_tinvestments[key2].includes(token_id)).length;
                    matrix[key1][key2] = commonLetters / trader_tinvestments[key1].length;
         
                }
            }

            this.buyer_scores = bsg
            this.mutual_investment_dependence = matrix
        },
        trader_toggle_select(trader_id){
           
            if ( this.selected_ids.includes(trader_id)){ 
                this.selected_ids = this.selected_ids.filter((x)=> x != trader_id)
            }else{
                this.selected_ids.push(trader_id)
            }
        },

        do_trader_ensure_complete_trades(){
            let trader_ids = []
            for (const trader_id in this.traders) { if (Object.hasOwnProperty.call(this.traders, trader_id)) { trader_ids.push(trader_id) } }
            console.log(trader_ids)
            
            this.loading.trader_trades_check = true
            this.msg = 'Ensuring data is congruent..'
            this.trader_ensure_complete_trades({qs:{},data:{'trader_ids':trader_ids}}).then((r)=>{
                this.loading.trader_trades_check = false
                this.do_trader_calculate_get_standard_scores(false)
                this.msg = 'done with congruency tests...'
            })
        },
        do_trader_calculate_get_standard_scores(forced){
            let trader_ids = []
            console.log(trader_ids)
            this.loading.trader_standard_scores = true
            
            const chunked_get_trader_standard_scores = (trader_ids, batchsize) => {
                if (this.standardScores  == undefined ){this.standardScores = {}}
                if (trader_ids.length == 0 ){ this.msg= 'Done.' ;return }
                this.msg = `Calculating scores for ${batchsize} Traders, ${trader_ids.length} Traders left.`
                this.trader_calculate_get_standard_scores({qs:{'forced':forced},data:{'trader_ids':trader_ids.slice(0, batchsize)}}).then((r)=>{
                    this.loading.trader_standard_scores = false
                    this.standardScores = {...this.standardScores, ...r.data }
                    this.calc_derivatives()
                    trader_ids.splice(0,batchsize)
                    chunked_get_trader_standard_scores(trader_ids,batchsize)
                })
            }
            chunked_get_trader_standard_scores(this.traderIds,3)

            // this.trader_calculate_get_standard_scores({qs:{'forced':forced},data:{'trader_ids':trader_ids}}).then((r)=>{
            //     this.loading.trader_standard_scores = false
            //     this.standardScores = r.data
            // })

        }

    },
    computed:{
        ...mapState([]),
        buyer_tids_sorted(){
            if(!this.buyer_scores){ return [] }
            let trader_ids = Object.keys(this.buyer_scores)
            trader_ids = trader_ids.sort((a,b)=> this.buyer_scores[b].avg - this.buyer_scores[a].avg)
            return trader_ids
        }

    },
    watch:{

    },
}
</script>
<style scoped lang="scss">
.corr-matrix{
    max-height: 100%;
    max-width:100%;
    overflow: scroll;
}
</style>