<template>
    <div>
        <v-container fluid>
            <v-row>
                <!-- <v-col cols="12" md="12"> -->
                    <!-- {{count}} -->
                    <!-- <h2>Historical Gas Price</h2> -->
                <!-- </v-col> -->
                <v-col cols="12" md="12">
                    <canvas id="scoreBarChart"></canvas>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import {Chart, registerables} from 'chart.js'
Chart.register(...registerables);

export default {
    name: 'chartjs-barchart',
    components:{
    },
    emits:[
    ],
    data(){return {
        charts:{}
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        data:{type:Array,default:Array},
        count:{type:Number,default:Number},
        labels:{type:Array,default:Array},
    },
    mounted(){
        // this.init_chart('historicalPrice',['s','k'],[{label:'ers' , data:[1,200], backgroundColor:'white'}])
    
    },
    methods:{
        ...mapActions([]),
        refresh(){
            this.init_chart('historicalPrice',['s','k'],[{label:'ers' , data:[1,200], backgroundColor:'white'}])
            
        },
        init_chart(chartname, labels, datasets){
            try{this.charts[chartname].destroy()}catch{}
            const ctx = document.getElementById(chartname);
            console.log("CONTEXT", ctx, datasets);
            this.charts[chartname] = new Chart(ctx, {
                type: 'bar',
                data: { labels: labels, datasets: datasets, },
                options: {  }
            });
        },

    },
    computed:{
        ...mapState([]),
    },
    watch:{
        count:function(nv,ov){
            if (nv > 0 ){
                this.init_chart("scoreBarChart",this.labels, this.data)
            }
        }
    },
}
</script>
<style scoped lang="scss">
</style>