<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <h3> Create new {{staticListType}} portfolio for {{selected_team.name}} </h3>
                    <v-text-field class="ma-1" label="List name" rounded color="white" hide-details outlined dense v-model="new_list.name" ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-textarea class="ma-1" label="List notes" rounded color="white" hide-details  outlined dense v-model="new_list.notes" ></v-textarea>
                </v-col>
                <v-col sm="12" md="12">
                    <v-btn style="float:right" class="ma-1" elevation="2" color="default" small  text @click="do_add_list"> save </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'my-list-add',
    components:{
    },
    emits:[
        'list_added'
    ],
    data(){return {
        new_list:{ name:'', notes:'', team_id:0}

        }
    },
    props: { 
        staticListType:{type:String,default:'pair'},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['static_pair_list_actions','static_trader_list_actions']),
        do_add_list(){
            this.new_list.team_id = this.selected_team.id
            if(this.staticListType == 'pair'){
                this.static_pair_list_actions({data:this.new_list,qs:{action:'create_list'}}).then( r=> {
                        this.new_list = { name:'', notes:'' }
                        this.$emit('list_added')
                })
            }
            if(this.staticListType == 'trader'){
                this.static_trader_list_actions({data:this.new_list,qs:{action:'create_list'}}).then( r=> {
                        this.new_list = { name:'', notes:'' }
                        this.$emit('list_added')
                })
            }
        }

    },
    computed:{
        ...mapState(['selected_team']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>