<template>
<div class="amt-wrap">

    <div v-if="amount">
        <div class="unit-display" v-if="unit == 'eth'"> Ξ {{amount.toFixed(4)}} </div>
        <div class="unit-display" v-else-if="unit == 'usd'"> $ {{ usd_amount(amount)}} </div>
        <div class="unit-display" v-else-if="unit == 'finney' ">∫ {{(amount*1000).toFixed(2).toLocaleString()}}</div>
        <div class="unit-display" v-else > no unit {{unit}}</div>
    </div>
    <div v-else-if="amount == 0 ">
        <div class="unit-display" v-if="unit == 'eth'"> Ξ {{amount.toFixed(4)}} </div>
        <div class="unit-display" v-else-if="unit == 'usd'"> $ {{ usd_amount(amount)}} </div>
        <div class="unit-display" v-else-if="unit == 'finney' ">∫ {{(amount*1000).toFixed(2).toLocaleString()}}</div>
        <div class="unit-display" v-else > no unit {{unit}}</div>
    </div>
    <div v-else>
        -
    </div>
</div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'base-amount',
    components:{
    },
    emits:[
    ],
    data(){return {
        unit:'eth',
        usd_p_eth:1828,

        }
    },
    props: { 
        //propName:{type:String,default:''},
        amount:{type:Number,default:Number},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
        usd_amount(amount){
            const usd_amount = amount *  this.usd_p_eth
            var formatted_amount = usd_amount.toLocaleString('en-US',{ minimumFractionDigits: 0, maximumFractionDigits: 0 })
            if (usd_amount > 1000){ formatted_amount = (usd_amount / 1000).toLocaleString('en-US',{ minimumFractionDigits: 1, maximumFractionDigits: 1 }) + 'k' }
            if (usd_amount > 500000){ formatted_amount = (usd_amount / 1000000).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' Mil' }
            // *usd_p_eth)
            return formatted_amount

        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.amt-wrap{
    float:right;
    font-family: monospace;
    padding-right: 5px;
    width:100%;
    white-space: nowrap;

}
</style>