<template>
    <div>
        <div v-if="loading">
             <v-progress-circular indeterminate color="white" ></v-progress-circular>
        </div>
        <v-container fluid v-if="trader_scores">
            <v-row>
                <v-col cols="12" md="6">
                    <table>
                          <tr> <th colspan="4" >Buyer Signal</th> </tr>
                        <tr>
                            <th>avg</th>
                            <th>std</th>
                            <th>skew</th>
                            <th>CV</th>
                        </tr>

                        <tr>
                            <td> {{round(trader_scores.score_buy_signal.score.max_price_multiplier.avg, 2)}} x </td>
                            <td> {{round(trader_scores.score_buy_signal.score.max_price_multiplier.std, 2)}} </td>
                            <td> {{round(trader_scores.score_buy_signal.score.max_price_multiplier.skw, 2)}} </td>
                            <td> {{round(cv(trader_scores.score_buy_signal.score.max_price_multiplier), 0)}} % </td>
                        </tr>
                    </table>
                </v-col>
                <v-col cols="12" md="6">
                    <table>
                        <tr> <th colspan="4">Seller Signal</th> </tr>
                        <tr>
                            <th>avg</th>
                            <th>std</th>
                            <th>skew</th>
                            <th>CV</th>
                        </tr>

                        <tr>
                            <td> {{round(trader_scores.score_sell_signal.score.min_price_multiplier.avg, 3)}} x </td>
                            <td> {{round(trader_scores.score_sell_signal.score.min_price_multiplier.std, 3)}} </td>
                            <td> {{round(trader_scores.score_sell_signal.score.min_price_multiplier.skw, 3)}} </td>
                            <td> {{round(cv(trader_scores.score_sell_signal.score.min_price_multiplier), 0)}} % </td>
                        </tr>
                    </table>
                </v-col>
                <v-col cols="12" md="12">
                    <table>
                        <tr><th  colspan="5" >Buy Sell Scores</th></tr>
                        <tr>
                            <th>score</th>
                            <th>avg</th>
                            <th>std</th>
                            <th>skew</th>
                            <th>CV</th>
                        </tr>
                        <tr>
                            <th>sell over buy </th> 
                            <td> {{round(trader_scores.score_complete_pair_trades.score.sell_price_over_buy_price.avg, 2)}}</td>
                            <td> {{round(trader_scores.score_complete_pair_trades.score.sell_price_over_buy_price.std, 2)}}</td>
                            <td> {{round(trader_scores.score_complete_pair_trades.score.sell_price_over_buy_price.skw, 2)}}</td>
                            <td> {{round(cv(trader_scores.score_complete_pair_trades.score.sell_price_over_buy_price), 1)}} % </td>
                        </tr>
                        <tr>
                            <th>hold time</th> 
                            <td> <time-display mode="timedelta" :time="trader_scores.score_complete_pair_trades.score.avg_hold_time.avg" ></time-display></td>
                            <td> 
                                <time-display mode="timedelta" :time="trader_scores.score_complete_pair_trades.score.avg_hold_time.std" ></time-display>
                            </td>
                            <td> 
                                <time-display mode="timedelta" :time="trader_scores.score_complete_pair_trades.score.avg_hold_time.skw" ></time-display>
                            </td>
                            <td> {{round(cv(trader_scores.score_complete_pair_trades.score.avg_hold_time),1)}} %</td>
                        </tr>
                        <tr>
                            <th> Total Tokens Invested In</th>
                            <td colspan="4"> {{trader_scores.score_complete_pair_trades.score.total_tokens_invested.avg}} Tokens </td>
                        </tr>
                        <tr>
                            <td colspan="5">
                                <div style="width:100%; display:flex" class="buysell-token-dist">
                                    <div :style="`width:${trader_scores.score_complete_pair_trades.score.pct_tokens_bought_not_sold.avg*100}%;background-color:green`" > 
                                        {{(trader_scores.score_complete_pair_trades.score.pct_tokens_bought_not_sold.avg*trader_scores.score_complete_pair_trades.score.total_tokens_invested.avg).toFixed(0)}} 
                                        <br>
                                        <small>
                                            Only Bought 
                                        </small> 
                                    </div>
                                    <div :style="`width:${trader_scores.score_complete_pair_trades.score.pct_tokens_bought_and_sold.avg*100}%;background-color:#ffff0082`" > 
                                        {{(trader_scores.score_complete_pair_trades.score.pct_tokens_bought_and_sold.avg*trader_scores.score_complete_pair_trades.score.total_tokens_invested.avg).toFixed(0)}} 
                                        <br>
                                        <small>
                                            Bought & Sold
                                        </small> 
                                    </div>
                                    <div :style="`width:${trader_scores.score_complete_pair_trades.score.pct_tokens_sold_not_bought.avg*100}%;background-color:red`" > 
                                        {{(trader_scores.score_complete_pair_trades.score.pct_tokens_sold_not_bought.avg*trader_scores.score_complete_pair_trades.score.total_tokens_invested.avg).toFixed(0)}} 
                                        <br>
                                        <small>
                                            Only Sold 
                                        </small> 
                                    </div>

                                </div>
                            </td>
                        </tr>
                    </table>
                </v-col>
                <v-col sm="12" md="6">
                    <table>
                        <tr> <th colspan="2"> Day Activity </th> </tr>
                        <tr>
                            <th>Percent Active in day</th>
                            <td>{{ round((trader_scores.score_day_hours_active.score.day_pct_active_hrs.avg * 100),0) }} %</td>
                        </tr>
                        <tr>
                            <th>63%  active within % of day</th>
                            <td>{{ round((((trader_scores.score_day_hours_active.score.day_pct_active_hrs.std*2)/24)*100 ),0) }} %</td>
                        </tr>
            
                    </table>
                </v-col>
                <v-col sm="12" md="6">
                    <table>
                        <tr> <th colspan="5"> Early Bird</th> </tr>
                        <tr>
                            <th>score</th>
                            <th>avg</th>
                            <th>std</th>
                            <th>skew</th>
                            <th>CV</th>
                        </tr>
                        <tr>
                            <th>th Buyer</th>
                            <td>{{ round(trader_scores.score_early_bird.score.avg_th_buyer.avg,0) }} th</td>
                            <td>{{ round(trader_scores.score_early_bird.score.avg_th_buyer.std,1)}}</td>
                            <td>{{ round(trader_scores.score_early_bird.score.avg_th_buyer.skw,1)}}</td>
                            <td>{{round(cv(trader_scores.score_early_bird.score.avg_th_buyer),1)}} % </td>
                        </tr>
                        <tr>
                            <th>th Seller</th>
                            <td>{{round(trader_scores.score_early_bird.score.avg_th_seller.avg,0)}} th</td>
                            <td>{{round(trader_scores.score_early_bird.score.avg_th_seller.std,1)}}</td>
                            <td>{{round(trader_scores.score_early_bird.score.avg_th_seller.skw,1)}}</td>
                            <td>{{round(cv(trader_scores.score_early_bird.score.avg_th_seller), 1)}} % </td>
                        </tr>
                    </table>
                </v-col>
            </v-row>
        </v-container>
        <!-- <pre>
            {{trader_scores}}
        </pre> -->
        <v-btn v-if="trader_scores" class="ma-0"  elevation="2" small @click="do_get_trader_score(true)" >force recalculate</v-btn>
        <v-btn v-else class="ma-0"  elevation="2" small @click="do_get_trader_score(false)" >get scores </v-btn>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import TimeDisplay from './time-display.vue'
export default {
    name: 'trader-standard-score-sheet',
    components:{
        TimeDisplay
    },
    emits:[
    ],
    data(){return {
        trader_scores:undefined,
        loading:false,
        selected_calc_sheet:{},
        }
    },
    props: { 
        //propName:{type:String,default:''},
        traderId:{type:[String,Number],default:Number},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_get_trader_score(false)
    
    },
    methods:{
        ...mapActions(['get_trader_score']),
        do_get_trader_score(forced){
            let score_commands = [
                {'score_name': 'score_buy_signal', 'args_spec':{'front_interval':60*60}},
                {'score_name': 'score_sell_signal', 'args_spec':{'front_interval':60*60}},
                {'score_name': 'score_complete_pair_trades', 'args_spec':{}},
                {'score_name': 'score_day_hours_active', 'args_spec':{}},
                {'score_name': 'score_early_bird', 'args_spec':{}},
                
                ]
            this.loading = true
            this.get_trader_score({qs:{ trader_id:this.traderId, forced:forced},data:{score_commands:score_commands}}).then((r)=>{
                this.trader_scores = r.data
                this.loading = false

            })
        },
        round(val, tofixed){
            try{
                return  val.toFixed(tofixed)
            }catch{
                return val
            }

        },
        cv(score){
            return (score.std/score.avg)*100
        },
        select_calc_sheet(score){
            console.log(score);

        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.buysell-token-dist{
    div{
        text-align: center;
    }
}
td{
    text-align:center;
}
</style>