<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <h5> Select your list and add </h5>
                    <my-list-selector  :staticListType="'trader'"  @list_selected="(l)=>{selected_list = l}" ></my-list-selector>
                </v-col>
                <v-col cols="12" md="12">
                    
        <v-btn class="ma-0"  elevation="2" small  light block @click="add_traders_to_list" >Add ({{selectedIds.length}}) to list</v-btn>
                </v-col>
            </v-row>
        </v-container>

        
        <!-- <pre>
            {{selected_list}}
            {{selectedIds}}
        </pre> -->
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import MyListSelector from './my-list-selector.vue'
export default {
    name: 'trader-static-list-add-ids',
    components:{
        MyListSelector,
    },
    emits:[
        'added'
    ],
    data(){return {
        selected_list:undefined
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        selectedIds:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['static_trader_list_actions']),
        add_traders_to_list(){
            this.static_trader_list_actions({qs:{action:'add_traders_to_list'},data:{trader_ids:this.selectedIds, list_id:this.selected_list.id}}).then((r)=>{
                this.ret = r.data
                this.$emit('added')
            })

        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>