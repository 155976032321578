<template>
    <div>
        <!-- {{traderIds}}  -->

                        <v-container fluid v-if="traderIds">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-container fluid>
                                        <v-row>

                                            <v-col cols="12" md="12">
                                                <div style = "display:flex;flex-wrap:wrap;">
                                                    <div v-for="dco in data_column_options" :key="dco.name" >
                                                        <v-btn class="ma-1" :color="selected.data_columns.includes(dco) ? 'green': '' " elevation="2" small @click="toggle_select_data_column(dco)" > {{dco.name}} </v-btn>

                                                    </div>
                                                        <v-btn class="ma-1" color=" " elevation="2" small @click="clear_cache" outlined> clear cache </v-btn>
                                                </div>
                                                
                                            </v-col>
                                            <v-col sm="12" md="10">
                                                <div class="aggtagswrap">
                                                    <div v-for=" (cnt,tag) in aggregate.tags" :key="tag">
                                                            
                                                            <v-chip 
                                                            v-if="tag"
                                                            @click="selected.tag = tag" :color="selected.tag == tag ? 'red' : 'gray'" 
                                                            small class="ma-1" 
                                                            style="text-transform:capitalize" >
                                                                ({{cnt}}) {{tag.replaceAll('_',' ')}} 
                                                            </v-chip>
                                                    </div>
                                                    <v-chip
                                                    @click="recalculate_tags" outlined
                                                    small class="ma-1" 
                                                    style="text-transform:capitalize"
                                                    >recalc</v-chip>
                                                </div>
                                                <!-- {{aggregate.tags}} -->
                                            </v-col>
                                            <v-col sm="12" md="2">
                                                <v-switch v-model="force_score_calc" color="red" :label="`Force Calculate  `" ></v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                         
                                    
                                    <table>
                                        <tr> 
                                            <th @click="mechanics.selected = [] " ></th>
                                            <th> ({{sorted_traders.length}}) trader </th>
                                            <th>Tags </th>
                                            <th v-for="dc in selected.data_columns" :key="dc.name" @click="mechanics.order_by = dc"> {{dc.name}} </th>
                                            <!-- <th v-for="score_col in score_cols" :key="score_col" @click="mechanics.order_by = score_col" @dblclick="dialog.range_selector= !dialog.range_selector"> {{score_col.replaceAll('_',' ')}}</th> -->
                                        </tr>
                                        <tr v-for="l in sorted_traders" :key="l.id" :class="is_trader_selected(l) ? 'selected_cell' : '' ">
                                            <td @click="trader_toggle_select(l)"> + </td>
                                            <td> 
                                                <trader-link-to-page :traderId="l"></trader-link-to-page>
                                            </td>
                                            <td style="display:flex; justify-content:center;">

                                                    <div  v-for="tag in tags[l]" :key="tag" >
                                                        
                                                        <v-chip 
                                                        v-if="tag"
                                                        @click="selected.tag = tag" :color="selected.tag == tag ? 'red' : 'gray'" 
                                                        small class="ma-1" 
                                                        style="text-transform:capitalize" >
                                                            {{tag.replaceAll('_',' ')}}
                                                        </v-chip>
                                                    </div>
                                                    <!-- &nbsp; -->

                                                
                                            </td>
                                            <td v-for="dc in selected.data_columns" :key="dc.name">
                                                <div v-if="dc.unit == 'x'">
                                                    {{ format_x_to_dec(data_cache[dc.cache_name][dc.score_metric][l], 3) }} x

                                                </div>
                                                <div  v-else-if="dc.unit == 's'">
                                                    <!-- {{ format_x_to_dec(data_cache[dc.cache_name][dc.score_metric][l], 3) }} s -->
                                                    <time-display :time="data_cache[dc.cache_name][dc.score_metric][l]" mode="timedelta"></time-display>
                                                    
                                                </div>
                                                <div v-else>
                                                    {{data_cache[dc.cache_name][dc.score_metric][l]}} 
                                                </div>
                                            </td>


<!-- 
                                            <td v-for="score_col in score_cols" :key="score_col"> 
                                                <div v-if="score_col.includes( 'pct_') ">
                                                    <small> {{(100*trader_score_data[l][score_col][disp_score_metric]).toFixed(1)}} % </small>
                                                    <v-progress-linear rounded :value="100*trader_score_data[l][score_col][disp_score_metric]"></v-progress-linear>
                                                
                                                </div>
                                                <div v-else-if="['sell_price_over_buy_price','max_price_multiplier','min_price_multiplier','avg_th_buyer','avg_th_seller', 'trades_per_token'].includes(score_col)">
                                                    
                                                    <div  v-if="trader_score_data[l]">
                                                        <div v-if="trader_score_data[l][score_col]">
                                                            {{format_x_to_dec(trader_score_data[l][score_col][disp_score_metric], score_col == 'trades_per_token'? 1 :3 )}} x
                                                        </div>
                                                        <div v-else>
                                                            n/a
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else-if="['avg_hold_time'].includes(score_col)">
                                                    <time-display mode="timedelta" :time="trader_score_data[l][score_col][disp_score_metric]"></time-display>
                                                </div>
                                                <div v-else> {{trader_score_data[l][score_col][disp_score_metric]}} </div>
                                            </td> -->



                                        </tr>
                                    </table>
                                    
                                </v-col>
                            </v-row>
        
                        </v-container>
                        <div v-else>
                            <center>
                                Loading ...
                            </center>
                        </div>
                        <!-- Note, to reintegrate the corralation matrix look at trader-selector-on-standard-scores corr matrix -->
    </div>
</template>
<script>
// Begin Component Imports
import TraderLinkToPage from './trader-link-to-page.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
import TimeDisplay from './time-display.vue'
export default {
    name: 'traders-group-table',
    components:{
        TraderLinkToPage,
        TimeDisplay
    },
    emits:[
 
               'traders_selected'
    ],
    data(){return {
        response:undefined,
        dialog:{
            scores:false,
            move_traders:false,
        },
        target_list:undefined,
        force_score_calc:false,
        disp_score_metric: 'avg',
        mechanics:{
            selected:[],
            order_by:'',
            range:{ 'col_name':undefined, 'min':'', 'max':'', }

        },
        trader_score_schema:{
            score_buy_signal:{          scores:{    max_price_multiplier:false},
                                        args_spec:{front_interval:60*60}},
            score_sell_signal:{         scores:{    min_price_multiplier:false},
                                        args_spec:{front_interval:60*60}},
            score_complete_pair_trades:{scores: {
                                                    sell_price_over_buy_price:false,
                                                    avg_hold_time:false,
                                                    pct_tokens_bought_not_sold:false,
                                                    pct_tokens_sold_not_bought:false,
                                                    pct_tokens_bought_and_sold:false,
                                                    total_tokens_invested:false,
                                                    trades_per_token:false, } ,
                                        args_spec:{}},
            score_day_hours_active:{    scores:{
                                                    day_pct_active_hrs:false},
                                        args_spec:{}},
            score_early_bird:{          scores:{
                                                    avg_th_buyer:false, 
                                                    avg_th_seller:false},
                                        args_spec:{}},
            },
        trader_score_data:{

        },
        score_cols:[],
        load_cnt : 0,
        selected:{ tag:undefined, data_columns:[] },

        data_columns: [],
        data_column_options: [
            // { name : "buy impact 3H +"        , score_name:"score_buy_signal"           , score_metric: "max_price_multiplier"       , unit:"x"   , cache_name:'score_buy_signal{"front_interval":3600}'  , args_spec:{front_interval:10800}, },
            // { name : "buy impact 1D +"        , score_name:"score_buy_signal"           , score_metric: "max_price_multiplier"       , unit:"x"   , cache_name:'score_buy_signal{"front_interval":3600}'  , args_spec:{front_interval:86400}, },
            // { name : "sell impact 3H -"       , score_name:"score_sell_signal"          , score_metric: "min_price_multiplier"       , unit:"x"   , cache_name:'score_sell_signal{"front_interval":10800}', args_spec:{front_interval:10800}, },
            // { name : "sell impact 1D -"       , score_name:"score_sell_signal"          , score_metric: "min_price_multiplier"       , unit:"x"   , cache_name:'score_sell_signal{"front_interval":10800}', args_spec:{front_interval:86400}, },
            { name : "buy impact 1H +"        , score_name:"score_buy_signal"           , score_metric: "max_price_multiplier"       , unit:"x"   , cache_name:'score_buy_signal{"front_interval":3600}'  , args_spec:{front_interval:3600 }, },
            { name : "tokens invested in"     , score_name:"score_complete_pair_trades" , score_metric: "total_tokens_invested"      , unit:"x" , cache_name:'score_complete_pair_trades{}'             , args_spec:{                    }  },
            { name : "average buy over sell"  , score_name:"score_complete_pair_trades" , score_metric: "sell_price_over_buy_price"  , unit:"x"   , cache_name:'score_complete_pair_trades{}'             , args_spec:{                    }  },
            { name : "sell impact 1H -"       , score_name:"score_sell_signal"          , score_metric: "min_price_multiplier"       , unit:"x"   , cache_name:'score_sell_signal{"front_interval":10800}', args_spec:{front_interval:3600 }, },
            { name : "average hold time"      , score_name:"score_complete_pair_trades" , score_metric: "avg_hold_time"              , unit:"s"   , cache_name:'score_complete_pair_trades{}'             , args_spec:{                    }  },
            { name : "tokens bought not sold" , score_name:"score_complete_pair_trades" , score_metric: "pct_tokens_bought_not_sold" , unit:"pct" , cache_name:'score_complete_pair_trades{}'             , args_spec:{                    }  },
            { name : "tokens sold not bought" , score_name:"score_complete_pair_trades" , score_metric: "pct_tokens_sold_not_bought" , unit:"pct" , cache_name:'score_complete_pair_trades{}'             , args_spec:{                    }  },
            { name : "tokens bought and sold" , score_name:"score_complete_pair_trades" , score_metric: "pct_tokens_bought_and_sold" , unit:"pct" , cache_name:'score_complete_pair_trades{}'             , args_spec:{                    }  },
            { name : "trades per token"       , score_name:"score_complete_pair_trades" , score_metric: "trades_per_token"           , unit:"x" , cache_name:'score_complete_pair_trades{}'             , args_spec:{                    }  },
            { name : "activity daily cycle"   , score_name:"score_day_hours_active"     , score_metric: "day_pct_active_hrs"         , unit:"pct" , cache_name:'score_day_hours_active{}'                 , args_spec:{                    }  },
            { name : "average th buyer"       , score_name:"score_early_bird"           , score_metric: "avg_th_buyer"               , unit:"th" , cache_name:'score_early_bird{}'                       , args_spec:{                    }  },
            { name : "average th seller"      , score_name:"score_early_bird"           , score_metric: "avg_th_seller"              , unit:"th" , cache_name:'score_early_bird{}'                       , args_spec:{                    }  },
        ],
        data_cache: {
            'score_buy_signal{"front_interval":3600}'  :{max_price_multiplier:{}},
            'score_buy_signal{"front_interval":10800}' :{max_price_multiplier:{}},
            'score_buy_signal{"front_interval":86400}' :{max_price_multiplier:{}},
            'score_sell_signal{"front_interval":3600}' :{min_price_multiplier:{}},
            'score_sell_signal{"front_interval":10800}':{min_price_multiplier:{}},
            'score_sell_signal{"front_interval":86400}':{min_price_multiplier:{}},
            'score_complete_pair_trades{}'             :{sell_price_over_buy_price:{},avg_hold_time:{},pct_tokens_bought_not_sold:{},pct_tokens_sold_not_bought:{},pct_tokens_bought_and_sold:{},total_tokens_invested:{},trades_per_token:{}},
            'score_day_hours_active{}'                 :{day_pct_active_hrs:{}},
            'score_early_bird{}'                       :{avg_th_buyer:{},avg_th_seller:{}},
        },
        data_aggregation_function : 'avg',
        tags:{},
        aggregate:{
            tags:{}
        }

        }
    },
    props: { 
        traderIds:{type:Array,default:Array},
    },
    mounted(){
        this.get_trader_tags()
    },
    methods:{
        ...mapActions(['get_trader_score', 'force_recalc_trades','tags_by_trader']),
        recalculate_tags(){

        },
        is_trader_selected(trader_id){
            return this.mechanics.selected.includes(trader_id)
        },
        toggle_select_data_column(dc){
            const is_selected = this.selected.data_columns.map(x=> x.name).includes(dc.name)
            if(is_selected){ this.selected.data_columns = this.selected.data_columns.filter((x)=> x.name != dc.name)}
            else{ 
                this.get_scores_2(dc)
                this.selected.data_columns.push(dc) 
            }
        },

        trader_toggle_select(trader_id){
            if ( this.is_trader_selected(trader_id)){ this.mechanics.selected = this.mechanics.selected.filter((x)=> x != trader_id) }
            else{ this.mechanics.selected.push(trader_id) }
            this.$emit('traders_selected', this.mechanics.selected)
        },
        get_scores_2(dc){
            const score_command =  [{ score_name:dc.score_name, args_spec: dc.args_spec }]
            for (let i = 0; i < this.traderIds.length; i++) {
                const tid = this.traderIds[i];
                if(this.data_cache[dc.cache_name][dc.score_metric][tid] != undefined){continue}
                this.get_trader_score({qs:{ trader_id:tid, forced:this.force_score_calc,return_dtype:'records'},
                                       data:{score_commands:score_command}}).then((r)=>{
                    console.log(r);
                    for (let i = 0; i < r.data.length; i++) {
                        const calcs = r.data[i];
                        // this.data_cache[dc.cache_name][dc.score_metric][tid] = calcs[this.data_aggregation_function]
                        this.$set(this.data_cache[dc.cache_name][calcs.score_metric], tid, calcs[this.data_aggregation_function])

                        
                    }
                    // // this.trader_score_data[tid]["trades"] = r.data.scores[0].index
                    // for (const score_name in score_schema.scores) {
                    //     this.trader_score_data[tid][score_name] = r.data.scores[0].score[score_name]
                    //     this.load_cnt += 1
                      
                    // }

                })

            }

        },
        clear_cache(){
            this.selected.data_columns = []
            this.data_cache= {
            'score_buy_signal{"front_interval":3600}'  :{max_price_multiplier:{}},
            'score_buy_signal{"front_interval":10800}' :{max_price_multiplier:{}},
            'score_buy_signal{"front_interval":86400}' :{max_price_multiplier:{}},
            'score_sell_signal{"front_interval":3600}' :{min_price_multiplier:{}},
            'score_sell_signal{"front_interval":10800}':{min_price_multiplier:{}},
            'score_sell_signal{"front_interval":86400}':{min_price_multiplier:{}},
            'score_complete_pair_trades{}'             :{sell_price_over_buy_price:{},avg_hold_time:{},pct_tokens_bought_not_sold:{},pct_tokens_sold_not_bought:{},pct_tokens_bought_and_sold:{},total_tokens_invested:{},trades_per_token:{}},
            'score_day_hours_active{}'                 :{day_pct_active_hrs:{}},
            'score_early_bird{}'                       :{avg_th_buyer:{},avg_th_seller:{}},
            }
        },

        get_trader_tags(){
            function aggregate_tags(tid_tags){
               var tag_cnt = {}
               for (const tid in tid_tags) {
                if (Object.hasOwnProperty.call(tid_tags, tid)) {
                    const tags = tid_tags[tid];
                    tags.forEach(tag => {
                        if(tag_cnt[tag] == undefined){ tag_cnt[tag] = 0}
                        tag_cnt[tag] += 1
                    });
                    
                }
               }
               return tag_cnt
                

            }
            this.tags_by_trader({qs:{},data:{trader_ids:this.traderIds}}).then((r)=>{
                this.tags= r.data
                this.aggregate.tags = aggregate_tags(this.tags)
            })
        },
        get_scores(score_name, score_schema){
            console.log("getting score...", score_name);

            // First build trader_score_data
            this.trader_score_data = { } 
            for (let i = 0; i < this.traderIds.length; i++) {
                const tid = this.traderIds[i];
                this.trader_score_data[tid] = {"trades":"-"}
                for (const score_name in score_schema.scores) {
                    if (Object.hasOwnProperty.call(score_schema.scores, score_name)) {
                        this.trader_score_data[tid][score_name] = "loading"
                    }
                }
                
            }

            //next build score columns 
            this.score_cols = []
            for (const score_name in score_schema.scores) {
                    if (Object.hasOwnProperty.call(score_schema.scores, score_name)) {
                        this.score_cols.push(score_name)
                    }
            }

            const score_command = [{ score_name:score_name, args_spec: score_schema.args_spec }]
            const nr_of_traders = this.traderIds.length
            this.load_cnt = 0 
            for (let i = 0; i < nr_of_traders; i++) {
                const tid = this.traderIds[i];
                this.get_trader_score({qs:{ trader_id:tid, forced:this.force_score_calc,return_dtype:'array'},
                                       data:{score_commands:score_command}}).then((r)=>{
                    
                    // this.trader_score_data[tid]["trades"] = r.data.scores[0].index
                    for (const score_name in score_schema.scores) {
                        this.trader_score_data[tid][score_name] = r.data.scores[0].score[score_name]
                        this.load_cnt += 1
                      
                    }

                })
            }
            
   



        },
        do_force_recalc_trades(){ this.force_recalc_trades({qs:{trader_ids:this.traderIds},data:{}}).then((r)=>{ }) },
        range_select(){
            for (const tid in this.trader_score_data) {
                const score = this.trader_score_data[tid][this.mechanics.order_by];
                const min = parseFloat(this.mechanics.range.min)
                const max = parseFloat(this.mechanics.range.max)
                console.log(tid, score, min, max);
                if (score > min){
                    if( score < max){
                        console.log("Pushing ", this.mechanics.selected, tid);
                        this.mechanics.selected.push(parseInt(tid))
                    }
                }
            
            }
            this.mechanics.range.min = ''
            this.mechanics.range.max = ''

        },
        format_x_to_dec(nr, precision){
            try{ return nr.toFixed(precision)  }
            catch{ return nr }
        }
    },
    computed:{
        ...mapState([]),

        sorted_traders(){
            if (this.traderIds == undefined){ return []}
            if (this.mechanics.order_by == ''){ return this.traderIds}
            if (this.mechanics.order_by == undefined){ return this.traderIds}
            return this.traderIds.sort((a,b)=> {
                const metric_cache = this.data_cache[this.mechanics.order_by.cache_name][this.mechanics.order_by.score_metric]
                return metric_cache[b] - metric_cache[a]
                // return this.trader_score_data[b][this.mechanics.order_by][this.disp_score_metric] - this.trader_score_data[a][this.mechanics.order_by][this.disp_score_metric]
                })
        }

    },
    watch:{
    },
}
</script>
<style scoped lang="scss">

td{
    text-align: center;
}
.selected_cell{
    background: #6130715e;
}
.selected_text{
    color:#b75ad65e;
}
.full-page{
    height:calc(100vh - 110px);
    overflow:scroll;
}

.aggtagswrap{
    display:flex;
    flex-wrap:wrap;
}

</style>