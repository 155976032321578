<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="4">
                    <v-select v-model="selected.score" :items="score_names" label="Score" dense rounded solo hide-details >
                        <template v-slot:selection="{ item, index }">
                            {{item.replaceAll('score_','').replaceAll('_',' ')}}
                            </template>
                        <template v-slot:item="{ item, index }">
                            {{item.replaceAll('score_','').replaceAll('_',' ')}}
                        </template>
                    </v-select>
                    
                </v-col>
                <v-col cols="12" md="4">
                    <v-select v-if="selected.score" v-model="selected.metric" :items="metric_names" label="Score" dense rounded solo hide-details >
                        <template v-slot:selection="{ item, index }">
                            {{item.replaceAll('score_','').replaceAll('_',' ')}}
                            </template>
                        <template v-slot:item="{ item, index }">
                            {{item.replaceAll('score_','').replaceAll('_',' ')}}
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select v-if="selected.metric" v-model="selected.agg" :items="['Average','Std','cv']" label="Aggregate" dense rounded solo hide-details >
                        <template v-slot:selection="{ item, index }">
                            {{item.replaceAll('score_','').replaceAll('_',' ')}}
                            </template>
                        <template v-slot:item="{ item, index }">
                            {{item.replaceAll('score_','').replaceAll('_',' ')}}
                        </template>
                    </v-select>
                </v-col>
                <v-col sm="12" md="4" v-if="selected.metric">
                    <v-text-field label="Min" v-model="constraint_query.min" outlined dense ></v-text-field>
                </v-col>
                <v-col sm="12" md="4" v-if="selected.metric">
                    <v-text-field label="Max" v-model="constraint_query.max" outlined dense ></v-text-field>
                </v-col>
                <v-col sm="12" md="4" v-if="constraint_query.max - constraint_query.min != 0">
                    <v-btn class="ma-0"  elevation="2" small block @click="add_constraint" >add constraint</v-btn>
                </v-col>
                <v-col sm="12" md="12">
                    <table>
                        <tr>
                            <th>score</th>
                            <th>metric</th>
                            <th>Aggregate</th>
                            <th>is between </th>
                        </tr>
                        <tr v-for="constraint in query.constraints" :key="`${constraint.score_name}${constraint.metric_name}`" >
                            <td> {{constraint.score_name.replaceAll('score_','').replaceAll('_',' ')}}</td>
                            <td> {{constraint.metric_name.replaceAll('score_','').replaceAll('_',' ')}}</td>
                            <td v-if=" constraint.agg"> {{constraint.agg.replaceAll('score_','').replaceAll('_',' ')}}</td>
                            <td v-else> - </td>
                            <td> {{constraint.min}} - {{constraint.max}}</td>
                            <td> </td>
                        </tr>
                    </table>
                    
                    
                </v-col>
                <v-col sm="12" md="12">
                    <h5> And then import to</h5>
                    <my-list-selector :staticListType="'trader'" @list_selected="e => query.to_list = e.id" ></my-list-selector>
                </v-col>
                <v-col sm="12" md="12">
                    <!-- {{query}} -->
                    {{msg}}
                    <v-btn class="ma-0"  elevation="2" small  block @click="do_schedule_person_batch_job" > Schedule mine job </v-btn>
                    
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import MyListSelector from './static_lists/my-list-selector.vue'
export default {
    name: 'traders-extract-from-pair-on-score-form',
    components:{
        MyListSelector
    },
    emits:[
    ],
    data(){return {
        msg:'',
        trader_score_schema:{
            count:{
                scores:{
                  btokens_per_stokens: false,
                  tokens_bought: false,
                  tokens_sold: false,
                  total_trades: false,
                  trades_per_btoken: false
                },
                 args_spec:{}

            },
            score_buy_signal:{          scores:{    max_price_multiplier:false},
                                        args_spec:{front_interval:60*60}},
            score_sell_signal:{         scores:{    min_price_multiplier:false},
                                        args_spec:{front_interval:60*60}},
            score_complete_pair_trades:{scores: {
                                                    sell_price_over_buy_price:false,
                                                    avg_hold_time:false,
                                                    pct_tokens_bought_not_sold:false,
                                                    pct_tokens_sold_not_bought:false,
                                                    pct_tokens_bought_and_sold:false,
                                                    total_tokens_invested:false,
                                                    trades_per_token:false, } ,
                                        args_spec:{}},
            score_day_hours_active:{    scores:{
                                                    day_pct_active_hrs:false},
                                        args_spec:{}},
            score_early_bird:{          scores:{
                                                    avg_th_buyer:false, 
                                                    avg_th_seller:false},
                                        args_spec:{}},
            },
        query:{
            pair_id:0,
            constraints:[
                // {score_name:'score_buy_signal', metric_name:'max_price_multiplier', min:0, max:0} //example
            ],
            to_list:0,
        },
        constraint_query: {
            min:0, max:0, agg:'Average'
        },
        selected:{
            score:undefined,
            metric:undefined,
            agg:'Average',

        },
        score_names : [],
        // metric_names : []
        
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        pairId:{type:Number,default:Number},
    },
    mounted(){
        this.score_names = Object.keys(this.trader_score_schema)
    
    },
    methods:{
        ...mapActions(['schedule_person_batch_job']),
        add_constraint(){
            this.query.constraints.push({
                score_name:this.selected.score,
                metric_name:this.selected.metric,
                min: this.constraint_query.min,
                max: this.constraint_query.max,
                agg: this.selected.agg,
            })
            this.constraint_query.min = 0 
            this.constraint_query.max = 0
            this.selected.score =undefined 
            this.selected.metric = undefined
            this.selected.agg = 'Average'
        },
        do_schedule_person_batch_job(){
            if(this.query.constraints.length == 0 ){this.msg = 'No constraints!'}
            this.schedule_person_batch_job({qs:{},data:{
                function_name:'filter_traders_from_pairs_and_put_in_list',
                function_args:{
                    target_list_id:this.query.to_list, 
                    pair_id:this.pairId, 
                    score_constraints:this.query.constraints,
                },
                team_id:this.user_data.info.selected_team.id
            }}).then((r)=>{
                this.msg = r.data.msg
            })

        }
        
    },
    computed:{
        ...mapState(['user_data']),
        metric_names(){
            if (this.selected.score == undefined){return []}
            return Object.keys(this.trader_score_schema[this.selected.score].scores)
        }
    },
    watch:{
        // 'selected.score': (nv,ov)=>{
        //     this.metric_names = 

        // }
    },
}
</script>
<style scoped lang="scss">
</style>