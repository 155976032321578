<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="4">
                    <time-interval-selector @interval="(e)=>{set_time_range(e);}" label="Time Frame" :startTimeframe="'1D'"></time-interval-selector>
                    <h5>Time frame</h5>

                </v-col>
                <v-col sm="12" md="4">
                    <time-interval-selector @interval="(i)=>{data_timeframe.candlesize = i ; }"  label="Set Candle Size"  :startTimeframe="'5m'"></time-interval-selector>
                    <h5>Candle Size</h5>
                    
                </v-col>
                <v-col sm="12" md="4">
                    <v-btn class="ma-0"  elevation="2" block @click="get_price_data" > get prices</v-btn>
                </v-col>

                <v-col cols="12" md="12" v-if="!is_loading">
                    <div v-if="p">
                    Δ Price {{delta_price}} %
                        <plotly-2d-ohlc 
                        :title="pair_name" 
                        :index="p.index"
                        :open="p.open"
                        :high="p.high"
                        :low="p.low"
                        :close="p.close"
                        @xframe_change="chart_timeframe_change"
                        ></plotly-2d-ohlc>
                    </div>
                    <div v-else>
                        <center>
                            Loading ...
                        </center>
                    </div>
                </v-col>
                <v-col sm="12" md="12">
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import Plotly2dOhlc from './plotly/plotly-2d-ohlc.vue'
import TimeIntervalSelector from './time-interval-selector.vue'
export default {
    name: 'pair-price-ohlc-graph',
    components:{
        Plotly2dOhlc,
        TimeIntervalSelector
    },
    emits:[
        'view_change'
    ],
    data(){return {
        p:undefined,
        data_timeframe:{
            start : (Date.now()/1000) - 60*60*24,
            stop: (Date.now()/1000) ,
            candlesize:60*5,
        },
        is_loading: false,
        delta_price:0,
        
     }
    },
    props: { 
        pair_name:{type:String,default:''},
        pair_id:{type:Number,default:Number},
    },
    mounted(){
        this.set_time_range( 60*60*24)
        this.get_price_data()
    
    },
    methods:{
        ...mapActions(['pair_view_price_ohlc']),
        set_time_range(dt){
            this.data_timeframe = {
                start : (Date.now()/1000) - dt,
                stop: (Date.now()/1000) ,
                candlesize:60*5,
        }
        this.data_timeframe.candlesize = 10
        this.decide_candle_size()

        },
        decide_candle_size(){
            const dt = this.data_timeframe.stop - this.data_timeframe.start
            if(dt >=  60*10 ) {this.data_timeframe.candlesize =  60} 
            if(dt >=  60*60*1 ) {this.data_timeframe.candlesize =  60*5} 
            if(dt >=  60*60*24 ) {this.data_timeframe.candlesize =  60*10} 
            if(dt >=  60*60*24*3 ) {this.data_timeframe.candlesize =  60*60} 
            if(dt >=  60*60*24*30 ) {this.data_timeframe.candlesize =  60*60*6} 

        },
        get_price_data(){
            console.log("PAIRRRIA",this.pair_id);
            if (this.pair_id == undefined){return }
        
            const qs = {
                start:this.data_timeframe.start, 
                stop:this.data_timeframe.stop,
                candlesize:this.data_timeframe.candlesize,
                pair_id:this.pair_id,
                }
            this.is_loading = true
            this.pair_view_price_ohlc({qs:qs,data:{}}).then((r)=>{
                    this.p = r.data
                    this.is_loading = false
                    this.delta_price = this.calc_price_delta(this.p.open)
                    })
        },
        chart_timeframe_change(view_change){
            console.log("View changed", view_change);
            this.$emit('view_change', view_change)
            const dt =  view_change.max - view_change.min 
            this.data_timeframe.start = view_change.min 
            this.data_timeframe.stop = view_change.max
            this.decide_candle_size(dt)
            this.get_price_data()

        },
        calc_price_delta(arr){
            if (arr.length == 0 ){ return '?' }
            // if( arr[0] == 0 ){ return '∞' }
            if (arr[arr.length -1] == 0 ){return '- ∞'}
            let firstNonZeroIndex = -1;

            for (let i = 0; i < arr.length; i++) {
                if (arr[i] !== 0) {
                    firstNonZeroIndex = i;
                    break;
                }
            }

            const ratio = arr[firstNonZeroIndex] / arr[arr.length - 1];
            return (ratio*100).toFixed(1);


        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
        pair_id(nv,ov){this.get_price_data}
    },
}
</script>
<style scoped lang="scss">
</style>