<template>
    <div>
        
        <v-select rounded solo outlined 
        background-color="transparent" 
        justify-center 
        class="text-center" 
        v-model="timeframe" 
        color="white" 
        :items="time_intervals.list" 
        :label="label" 
        :hint="`${interval}`"
        dense  
        prepend-icon="mdi-clock" 
        hide-details ></v-select>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'time-interval-selector',
    components:{
    },
    emits:[
        'interval'
    ],
    data(){return {
        timeframe:undefined,
        interval:undefined,
        }
    },
    props: { 
        startTimeframe:{type:String,default:''},
        startTimeSeconds:{type:Number,default:Number},
        label:{type:String,default:'In The Last'},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        if(this.startTimeframe.length != 0){
            this.timeframe = this.startTimeframe
            this.interval = this.time_intervals.seconds[this.timeframe]
        }
        if(this.startTimeSeconds){
            this.interval = this.startTimeSeconds 
        }
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState(['time_intervals']),
    },
    watch:{
        timeframe(newV,oldV){
            this.interval=this.time_intervals.seconds[newV]
            this.$emit('interval', this.interval)
            console.log('interval changed ', this.interval);
        }
    },
}
</script>
<style scoped lang="scss">




</style>