<template>
    <div>
        this is pair-ml-labs
        <div v-if="data">

            <plotly-2d-scatter :xData="data.graph_data.x" :yData="data.graph_data.y"></plotly-2d-scatter>
        </div>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import Plotly2dScatter from '@/components/plotly/plotly-2d-scatter.vue'
export default {
    name: 'pair-ml-labs',
    components:{
        Plotly2dScatter
    },
    emits:[ ],
    data(){return {
        data:undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_trader_metrics_counts_token_test_pca()
    
    },
    methods:{
        ...mapActions(['trader_metrics_counts_token_test_pca']),
        do_trader_metrics_counts_token_test_pca(){
            this.trader_metrics_counts_token_test_pca({qs:{},data:{}}).then((r)=>{
                this.data = r.data
            })
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>