<template>
    <div>
        <v-sheet rounded="sm">
            <!-- {{xData}} {{yData}} -->
            <div id="line-chart"></div>
        </v-sheet>
 
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import Plotly from 'plotly.js-dist-min'


export default {
    name: 'plotly-2d-ohlc',
    components:{
    },
    emits:[
        'xframe_change'
    ],
    data(){return { 
        chartData:{}

    }
    },
    props: { 
        //x_data:{type:Object,default:Object},
        title:{type:String,default:'ohlc'},
        index:{type:Array,default:Array},
        open:{type:Array,default:Array},
        high:{type:Array,default:Array},
        low:{type:Array,default:Array},
        close:{type:Array,default:Array},

        metaData: {type:Object, default: () => {return {xAxisName:'',yAxisName:''}}}
    },
    mounted(){

            this.init_chart(this.index, this.open, this.high, this.low, this.close)

    },
    methods:{
        ...mapActions([]),
        init_chart(index, open, high, low , close){

    // const up_rgb = '39,73,125'
    const up_rgb = '80, 174, 82'
    // const down_rgb = '174, 86, 80'
    const down_rgb = '80, 80, 80'
    
    const trace = {
            x: index,
            close: close,
            high: high,
            low:low,
            open: open,
            // increasing: {line: {color: `rgba(${up_rgb},1)`}}, 
            // decreasing: {line: {color: `rgba(${down_rgb},1)`}},   
            increasing: {line: {color: `rgba(${up_rgb},1)`}, fillcolor: `rgba(${up_rgb},0.8)`}, 
            decreasing: {line: {color: `rgba(${down_rgb},1)`}, fillcolor: `rgba(${down_rgb},0.1)`},  
            type: 'candlestick', 
            name: this.titleName,
    };

    const layout = {
        title: {text:''},
           margin: {
        t: 6 
    },
        plot_bgcolor: 'transparent',
        paper_bgcolor: 'transparent',
        font: { color: 'white',  },

        xaxis: { title: '' , gridcolor: '#ffffff11', type: 'date',  },
        yaxis: { title: '', gridcolor: '#ffffff11', },
        shapes: [
            // {
            //     type: 'line',  x0: '2023-09-01', x1: '2023-09-01', y0: 102, y1: 105,       
            //     line: { color: 'red',   width: 2,       },
            // },
        ],
    };

    const chartContainer = document.getElementById('line-chart');
    Plotly.newPlot('line-chart', [trace], layout);
        
    chartContainer.on('plotly_click', (eventData) => {
        if (eventData.points.length > 0) {
            const clickedPoint = eventData.points[0];
            const xValue = clickedPoint.open;
            const yValue = clickedPoint.y;
            const text = clickedPoint.text;
            console.log(`Clicked Data Point: X=${xValue}, Y=${yValue}, Text="${text}"`);
        }
    });



// Add an event listener for the relayout event
chartContainer.on('plotly_relayout', (eventData) =>{
        if ('xaxis.range[0]' in eventData && 'xaxis.range[1]' in eventData) {
            var minTime = new Date(eventData['xaxis.range[0]']);
            var maxTime = new Date(eventData['xaxis.range[1]']);
            this.$emit('xframe_change',{min:minTime/1000,max:maxTime/1000})
        }
    });


        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
svg{
    background-color: red!important;
    color:rgba(174, 86, 80,1);
    color:rgba(80, 174, 82,1);
}
</style>