<template>
    <div>
        <v-btn elevation="2" color="default" small @click="dialog= !dialog">Source Analysis</v-btn>
        <!-- popup Title  -->
        <v-dialog v-model="dialog"  max-width="90vw" >
            <v-sheet rounded="sm">

                <v-container fluid>
                    <v-row>
                        <v-col sm="12" md="12">
                            <h2 v-if="pair">
                                <pair-link-to-page :pair="pair"></pair-link-to-page>
                            </h2>
                        </v-col>

                        <v-col sm="12" md="6">
                            <h4>Contract meta</h4>
                            <pair-verified-contract-info :pairId="this.pairId" ></pair-verified-contract-info>
                        </v-col>

                        <v-col sm="12" md="6">
                            <h4>Trader notes</h4>
                            <v-textarea label="Notes" outlined dense></v-textarea>
                        </v-col>
                        <v-col sm="12" md="12">
                            <h4>Locked Liquidity</h4>
                            <div class="locked-liq-wrap">
                                <v-checkbox label="Unicrypt"></v-checkbox>
                                <v-checkbox label="Team Finance"></v-checkbox>
                                <v-checkbox label="Gempad"></v-checkbox>
                                <v-checkbox label="Dxsale"></v-checkbox>
                                <v-checkbox label="Flocki"></v-checkbox>
                            </div>
                        </v-col>
                    </v-row>

  
   
                </v-container>
            </v-sheet>
        </v-dialog>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import PairLinkToPage from './pair-link-to-page.vue'
import PairVerifiedContractInfo from './pair-verified-contract-info.vue'
export default {
    name: 'pre-ape-checklist',
    components:{
        PairVerifiedContractInfo,
        PairLinkToPage
    },
    emits:[
    ],
    data(){return {

        dialog:false,
        new_action:'',
        checklist:{
            price:{
                since_signal:"",
                lately:"",
            }
        },
        pair:undefined,
        price:undefined,
        token_name:undefined,
        token_symbol:undefined,

        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        pairId:{type:[Number, String],default:Number},
        //list:{type:Array,default:Array},
    },
    mounted(){
        if(this.pairId){

            this.update_token_info()
        }
    
    },
    methods:{
        ...mapActions(['swap_app_pair_price']),
        update_token_info(){
                this.swap_app_pair_price({ qs:{pair_id:this.pairId}, data: {}}).then(r=>{
                
                this.price = r.data
                this.pair = r.data.pair

        
        
            })

        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
        pairId(nv,ov){
            this.update_token_info()

        }
    },
}
</script>
<style scoped lang="scss">
.locked-liq-wrap{
    display:flex;
    align-content: space-between;
    justify-content: space-around;
}
</style>