<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="4">
                    <h5>Add</h5>
                    <v-chip  class="ma-1" v-for="p in selected_pairs" :key="p.id" small > {{p.name}}</v-chip>
                </v-col>
                <v-col cols="12" md="8">
                   <my-list-selector @list_selected="(e)=>{selected_list = e}"></my-list-selector> 
                   <div v-if="selected_list" >
                     <my-list-preview  :list_id="selected_list.id" ></my-list-preview>
                   </div>
                </v-col>
                <v-col sm="12" md="12">
                    <v-btn class="ma-1" elevation="2" color="default" small block @click="do_add_pairs_to_lists"> 
                        <div>
                            {{msg}} 
                        </div>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import MyListSelector from '@/components/static_lists/my-list-selector.vue'
import MyListPreview from '@/components/static_lists/my-list-preview.vue'
// import IntelPairList from './intel-pair-list.vue'
export default {
    name: 'my-list-add-pair',
    components:{
        MyListSelector,
        MyListPreview,
        // IntelPairList
    },
    emits:[
        'added'
    ],
    data(){return {
        selected_list:undefined,
        msg:'Add tokens to lists',
        }
    },
    props: { 
        //propName:{type:String,default:''},
        selected_pairs:{type:Array,default:Array},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['static_pair_list_actions']),
        do_add_pairs_to_lists(){
            const pair_ids = this.selected_pairs.map(x=> x.id)
            const data = {
                'pair_ids':pair_ids,
                'list_id':this.selected_list.id
            }
            console.log(data);
            this.msg = 'Adding...'
            this.static_pair_list_actions({data:data,qs:{action:'add_pairs_to_list'}}).then(
                r=> {
                this.lists = r.data
                this.msg = 'Added to list.'
                setTimeout(()=>{ this.$emit('added') }, 1000)
                
                }

                )
        },
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>