<template>
    <div>
        <div v-if="pair" >
                <v-app-bar>
                    <v-toolbar-title dense  >
                        {{pair.symbol}}  
                        <sub> [ {{pair.id}} ] </sub>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                        <div @click="show_full_name = ! show_full_name"  v-if="show_full_name">
                            {{pair.name}}
                        </div>
                        <div  v-else>
                            {{ pair.name.slice(0,50) }} <span v-if="pair.name.length > 50">...</span>
                        </div>
                    <v-spacer></v-spacer>
                    <v-text-field class="mt-6 mx-4" color="white" label="0x..." @keydown.enter="do_search" v-model="search" rounded outlined dense prepend-inner-icon="mdi-magnify" ></v-text-field>
                </v-app-bar>

                <v-container fluid v-if="pair">
                    <v-row>
                        <v-col sm="12" md="12">
                            <v-sheet rounded="sm" class="content" >
                                <v-tabs centered color="white" v-model="page_nav_index">
                                        <v-tab>chart</v-tab>
                                        <v-tab>Info & events</v-tab>
                                        <v-tab>trades </v-tab>
                                        <v-tab>Safety</v-tab>
                                        <v-tab>Chatter</v-tab>
                                        <!-- chart -->
                                        <v-tab-item>
                                            <v-container fluid>
                                                <v-row>
                                                    <v-col sm="12" md="12">
                                                        <div style="height:50vh;border-radius:0px;" >
                                                            <div >
                                                                <pair-price-ohlc-graph 
                                                                :pair_id="pair.id" 
                                                                :pair_name="pair.name"
                                                                @view_change="graph_view_change"
                                                                ></pair-price-ohlc-graph>
                                                            </div>
                                                        </div>
                                                    </v-col>

                                                    <v-col cols="12" md="12" v-if="pair">
                                                        <v-sheet >
                                                            <v-container fluid>
                                                                <v-row>
                                                 
                                                                    <v-col cols="12" md="6">
                                                                        <table class="info-table" >
                                                                            <tr>
                                                                                <th colspan=3> info</th>
                                                                            </tr>
                                                                            <tr> 
                                                                                <th>contract address</th>
                                                                                <td> {{pair.currency_address}}  </td>
                                                                                <td>  
                                                                                    <external-link-dextools :tokenAddress="pair.currency_address"></external-link-dextools>
                                                                                    <v-icon @click="copyToClipboard(pair.currency_address)" class="ml-1" small >mdi-content-copy</v-icon> 
                                                                                </td>
                                                                            </tr>
                                                                            <tr> 
                                                                                <th>pair address</th>
                                                                                <td> {{pair.pair_address}}  </td>
                                                                                <td>  
                                                                                    <external-link-dextools :tokenAddress="pair.pair_address"></external-link-dextools>
                                                                                    <v-icon @click="copyToClipboard(pair.pair_address)" class="ml-1" small >mdi-content-copy</v-icon>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Liquidity</th>
                                                                                <td colspan=2 > <base-amount :amount="pair.liquidity *1e-18"></base-amount> </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Debug</th>
                                                                                <td colspan="2">
                                                                                    <table>
                                                                                        <tr>
                                                                                            <th colspan="2">price</th>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>last request</th>
                                                                                            <td> <time-display :time="pair.debug.price.last_snapshot"></time-display></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>scheduled for</th>
                                                                                            <td> <time-display :time="pair.debug.price.check_deadline"></time-display> </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th colspan="2">
                                                                                                <v-btn class="ma-0"  elevation="2" small  block @click="0" > schedule check now</v-btn>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </table>

                                                                                </td>
                                                                            </tr>
                                                                          
                                                                        </table>
                                                                    </v-col>
                                                                    <v-col sm="12" md="6">
                                                                        <table v-if="section_traders_trades_explore_info">
                                                                            <tr>
                                                                                <th colspan="2"> focus </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>begin</th>
                                                                                <th>end</th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <time-display :time="time_frame.min"></time-display>
                                                                                </td>
                                                                                <td>
                                                                                    <time-display :time="time_frame.max"></time-display>

                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Buy trades</th>
                                                                                <th>sell Trades</th>
                                                                            </tr>
                                                                            <tr>
                                                                               
                                                                                <td>
                                                                                    {{section_traders_trades_explore_info.buy_cnt}}
                                                                                </td>
                                                                                <td>
                                                                                    {{section_traders_trades_explore_info.sell_cnt}}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Buyers</th>
                                                                                <th>Sellers</th>

                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    {{section_traders_trades_explore_info.buyers.length}}
                                                                                </td>
                                                                                <td>
                                                                                    {{section_traders_trades_explore_info.sellers.length}}
                                                                                </td>
                                                                         
                                                                            </tr>
                             

                                            
                                                                            <tr>
                                                                                <td >
                                                                               
                                                                                    <table>
                                                                                        <tr>
                                                                                            <th colspan="2">Buyers breakdown</th>
                                                                                        </tr>
                                                                                        <tr v-for="btd in section_traders_trades_explore_info.buy_tag_distribution" :key="btd.tag">
                                                                                            <td> 
                                                                                                <div class="text-right" v-if="btd.tag" :style="`text-transform:capitalize; color:${str_to_color(btd.tag)};`">
                                                                                                    {{btd.tag.replaceAll('_',' ')}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td> {{(btd.pct*100).toFixed(1)}}  % </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                                <td >
                                                                                    <table>
                                                                                        <tr>
                                                                                            <th colspan="2">Sell breakdown</th>
                                                                                        </tr>
                                                                                        <tr v-for="btd in section_traders_trades_explore_info.sell_tag_distribution" :key="btd.tag">
                                                                                            <td> 
                                                                                                 <div class="text-right" v-if="btd.tag" :style="`text-transform:capitalize; color:${str_to_color(btd.tag)};`">
                                                                                                    {{btd.tag.replaceAll('_',' ')}}
                                                                                                </div>    
                                                                                            </td>
                                                                                            <td> {{(btd.pct*100).toFixed(1)}}  % </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <!-- <tr>
                                                                                <th colspan=4>
                                                                                    <pre>

                                                                                    {{section_traders_trades_explore_info}}
                                                                                    </pre>
                                                                                    
                                                                                </th>
                                                                             
                                                                            </tr> -->
                                                                        </table>
                                                                        <v-btn class="ma-0"  elevation="2"  
                                                                            small block  
                                                                            :disabled= "tag_distribution_button_text != 'Force Recalculate' "
                                                                            @click="do_pair_get_section_traders_trades_explore_info(true)" >
                                                                            {{tag_distribution_button_text}}
                                                                        </v-btn>
                                                                        
                                                                    </v-col>
                                                                   
                                                                </v-row>
                                                            </v-container>
                                                        </v-sheet>
                                                    </v-col> 

                                                </v-row>
                                            </v-container>
                                        <!-- actions  -->
                                        </v-tab-item>
                                        
                                        <v-tab-item>
                                            <v-container fluid>
                                                <v-row>
                                                    <v-col cols="12" md="12">
                                                        <v-sheet >
                                                            <v-container fluid>
                                                                <v-row>
                                                 
                                                                    <v-col cols="12" md="6">
                                                                        <table class="info-table" >
                                                                            <tr> 
                                                                                <th>contract address</th>
                                                                            </tr>
                                                                            <tr> 
                                                                                <td style="display:flex; justify-content:center;">
                                                                                    <small> {{pair.currency_address}}  </small> 
                                                                                    <external-link-dextools :tokenAddress="pair.currency_address"></external-link-dextools>
                                                                                    <v-icon @click="copyToClipboard(pair.currency_address)" class="ml-1" small >mdi-content-copy</v-icon>
                                                                                </td> 
                                                                            </tr>
                                                                        </table>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <table class="info-table" >
                                                                            <tr>
                                                                                <th>pair address</th>
                                                                            </tr> 
                                                                            <tr>
                                                                                <td style="display:flex; justify-content:center;" >
                                                                                    <small> {{pair.pair_address}}  </small>
                                                                                    <external-link-dextools :tokenAddress="pair.pair_address"></external-link-dextools> 
                                                                                    <v-icon @click="copyToClipboard(pair.pair_address)" class="ml-1" small >mdi-content-copy</v-icon>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </v-col>

                                                                    <v-col cols="12" md="6">
                                                                        <table class="info-table" >
                                                                            <tr> <th>created at </th></tr><tr> <td>
                                                                                
                                                                                <time-display :time="pair.created_at"></time-display>
                                                                                <time-display :time="pair.created_at" mode="timesince" ></time-display>
                                                                                </td> </tr>
                                                                        </table>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <table class="info-table" >
                                                                            <tr> <th colspan="2">Activity</th> </tr>
                                                                            <tr> <th>Transactions</th> <td> 0</td> </tr>
                                                                            <tr> <th>Traders</th> <td> 0</td> </tr>
                                                                        </table>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <table class="info-table" >
                                                                            <tr> <th>Liquidity</th> </tr><tr><td> <base-amount :amount="pair.liquidity *1e-18"></base-amount> <br> <br>  </td> </tr>
                                                                        </table>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <table class="info-table" >
                                                                            <tr> <th>Links</th> </tr><tr><td>  
                                                                                <router-link :to="`/pair/${pair.currency_address}`" class="ml-1"> Illium </router-link>
                                                                                <external-link-dextools></external-link-dextools>
                                                                            </td> </tr>
                                                                        </table>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </v-sheet>
                                                    </v-col> 
                                                    <!-- tx events  -->
                                                    <v-col sm="12" md="12">
                                                        <h3> tx Events</h3>
                                                        <table>
                                                            <tr>
                                                                <th>time</th>
                                                                <th>action</th>
                                                                <th>val</th>
                                                            </tr>
                                                            <tr v-for="te in pair.tx_events" :key="te.id" >
                                                                <td>{{  epoch_to_string(te.time_epoch)}}</td>
                                                                <td>{{te.action}}</td>
                                                                <td>
                                                                    <span v-if="te.data.value != undefined"> {{(te.data.value *1e-18).toFixed(4)}}</span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        
                                                    </v-col>

                                                </v-row>
                                            </v-container>

                                        </v-tab-item>
                                        <!-- trades  -->
                                        <v-tab-item>
                                            <v-sheet >
                                                <!-- {{time_frame}} -->
                                                <pair-trade-list :pairId="pair.id" :startEpoch="time_frame.min" :endEpoch="time_frame.max" ></pair-trade-list>
                                            </v-sheet>
                                        </v-tab-item>
                                        <!-- safety  -->
                                        <v-tab-item>
                                            <v-sheet class="pair-section">
                                                <v-container fluid>
                                                    <v-row>
                                                        <v-col cols="12" md="12" v-if="pair">
                                                            <pre-ape-checklist :pairId="pair.id"></pre-ape-checklist>
                                                        </v-col>
                                                        <v-col cols="12" md="12">
                                                            <h3>Simulated Trades</h3>
                                                            <table>
                                                                <tr>
                                                                    <th>id</th>
                                                                    <th>time</th>
                                                                    <th >tests</th>
                                                                    <!-- <th >info</th> -->
                                                                    <!-- <th colspan="1">errors</th> -->
                                                                    <!-- <th>Eth/Tkn</th> -->
                                                                    <!-- <th colspan="2">tax</th> -->
                                                                </tr>
                                                                <tr v-for="t in pair.simulated_trades" :key="t.id">
                                                                    <td @click="selected_sim = t; sim_dialog=true" > {{t.id}} </td>
                                                    
                                                                    <td> {{epoch_to_string(t.time_epoch)}} </td>
                                                                    <td>
                                                                        <div> 
                                                                            <v-icon v-if=" t.conclusions.received_tokens" color="green" small class="ma-0">mdi-check</v-icon> 
                                                                            <v-icon v-else color="red" small class="ma-0">mdi-close</v-icon> 
                                                                            Got Tkns 
                                                                        </div>
                                                                        <div> 
                                                                            <v-icon v-if=" t.conclusions.sold_tokens" color="green" small class="ma-0">mdi-check</v-icon> 
                                                                            <v-icon v-else color="red" small class="ma-0">mdi-close</v-icon> 
                                                                            Sellable Tkns
                                                                        </div>
                                                                        <div>  Tax: {{ (t.conclusions.tax*100).toFixed(1) }} %</div>
                                                                    </td>
                                                                    <!-- <td>{{t.buy_gas_used.toLocaleString()}}</td> -->
                                                                    <!-- <td>{{t.sell_gas_used.toLocaleString() }} </td> -->
                                                                    <!-- <td style="display: flex; "> 
                                                                        <div v-if="! t.success['sim']" class="error-box">G</div>
                                                                        <div v-if="! t.success['buy']" class="error-box">B</div>
                                                                        <div v-if="! t.success['sell']" class="error-box">S</div>
                                                                
                                                                    </td> -->
                                                                    <!-- <td>{{t.price_base_per_token < 1 ? t.price_base_per_token.toFixed(12) : t.price_base_per_token}}</td> -->
                                                                    <!-- <td :class="{'high-tax': t.buy_tax > 0.5 }">{{(t.buy_tax *100).toFixed(1) }} % </td> -->
                                                                    <!-- <td :class="{'high-tax': t.sell_tax > 0.5 }">{{(t.sell_tax *100).toFixed(1) }} % </td> -->
                                                                
                                                                </tr>
                                    
                                                            </table>
                                                            <v-btn class="ma-1" elevation="2" color="white" light small block @click="do_request_simulated_trade" > request new simulated trade</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                          
                                            </v-sheet>
                                        </v-tab-item>
                                        <!-- calls -->
                                        <v-tab-item>
                                            <v-sheet >
                                                <h3>Calls</h3>
                                                <!-- {{pair.calls}} -->
                                                <table>
                                                    <tr> 
                                                        <th>time</th>
                                                        <th>group</th>
                                                    </tr>
                                                    <tr v-for="m in pair.calls" :key="m.id">
                                                        <td>
                                                            {{epoch_to_string(m.time_epoch)}}
                                                        </td>
                                                        <td>{{m.group.name}}</td>
                                                    </tr>
                                                </table>
                                            </v-sheet>
                                        </v-tab-item>
                                </v-tabs>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-container>

                <v-snackbar v-model="snackbar" > {{ feedback }}
                    <template v-slot:action="{ attrs }">
                    <v-btn color="white" text v-bind="attrs" @click="snackbar = false" >
                        Close
                    </v-btn>
                    </template>
                </v-snackbar>

                <v-dialog v-model="sim_dialog"  max-width="90vw" >
                    <v-sheet rounded="sm">
                        <v-tabs centered color="white"  v-if="selected_sim">
                                <v-tab>base</v-tab>
                                <v-tab>tx logs</v-tab>
                                <v-tab>data</v-tab>
                                
                                <v-tab-item>
                                    <v-container fluid >
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <table>
                                                    <tr>
                                                        <th>Tokens Bought</th><td>{{ selected_sim.tokens_bought }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Tokens Sold</th><td>{{ selected_sim.tokens_sold }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Base Sold</th><td> <base-amount :amount="selected_sim.base_sold"></base-amount></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Base Bought (Back)</th><td> <base-amount :amount="selected_sim.base_bought"></base-amount></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Liquidity</th><td> <base-amount :amount="selected_sim.liquidity.liquidity_base"></base-amount></td>
                                                    </tr>
                                                </table>
                                            </v-col>
                                    
                                        </v-row>
                                    </v-container>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <h3> 

                                                    {{ selected_sim.pair.name.slice(0,40) }}
                                                </h3>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th colspan="4">buy</th>
                                                        </tr>

                                                        <tr>
                                                            <td></td>
                                                            <th>before</th>
                                                            <th>after</th>
                                                            <th>Total</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Token</th>
                                                            <td> {{disp_tokens(selected_sim.logs.buy.balance_before.currency,selected_sim.pair.decimals) }}</td>
                                                            <td> {{disp_tokens(selected_sim.logs.buy.balance_after.currency,selected_sim.pair.decimals)}}</td>
                                                            <td> {{disp_tokens(selected_sim.logs.buy.balance_after.currency - selected_sim.logs.buy.balance_before.currency ,selected_sim.pair.decimals)}}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Base</th>
                                                            <td> {{selected_sim.logs.buy.balance_before.native}}</td>
                                                            <td> {{selected_sim.logs.buy.balance_after.native}}</td>
                                                            <td> {{selected_sim.logs.buy.balance_after.native - selected_sim.logs.buy.balance_before.native}}</td>
                                                        </tr>
                                                        <tr>
                                                            <th> Gas</th>
                                                            <td></td>
                                                            <td>{{selected_sim.logs.buy.action_data.gas_used.toLocaleString()}} u</td>
                                                            <td>{{ selected_sim.logs.buy.action_data.gas_used * selected_sim.logs.buy.action_data.gas_price *1e-18 }}</td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody>
                                                        <tr>
                                                            <th colspan="4">sell</th>
                                                        </tr>

                                                        <tr>
                                                            <td></td>
                                                            <th>before</th>
                                                            <th>after</th>
                                                            <th>diff</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Token</th>
                                                            <td> {{disp_tokens(selected_sim.logs.sell.balance_before.currency,selected_sim.pair.decimals)}}</td>
                                                            <td> {{disp_tokens(selected_sim.logs.sell.balance_after.currency,selected_sim.pair.decimals)}}</td>
                                                            <td> {{disp_tokens(selected_sim.logs.sell.balance_after.currency - selected_sim.logs.sell.balance_before.currency,selected_sim.pair.decimals)}}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Base</th>
                                                            <td> {{selected_sim.logs.sell.balance_before.native}}</td>
                                                            <td> {{selected_sim.logs.sell.balance_after.native}}</td>
                                                            <td> {{selected_sim.logs.sell.balance_after.native - selected_sim.logs.sell.balance_before.native}}</td>
                                                        </tr>
                                                        <tr>
                                                            <th> Gas</th>
                                                            <td></td>
                                                            <td>{{selected_sim.logs.sell.action_data.gas_used.toLocaleString()}} u</td>
                                                            <td>{{ selected_sim.logs.sell.action_data.gas_used * selected_sim.logs.sell.action_data.gas_price *1e-18 }}</td>
                                                        </tr>
                                                    </tbody>
                                            
                                                </table>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-tab-item>
                                <v-tab-item>
                                    <sim-test-data :data="selected_sim"></sim-test-data>
                                
                                    <!-- <pre> {{ selected_sim }} </pre> -->
                                </v-tab-item>
                        </v-tabs>
                    </v-sheet>
                </v-dialog>
        </div>
        <div v-else>
            Loading ... 
        </div>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import BaseAmount from './base-amount.vue'
import ExternalLinkDextools from './external-link-dextools.vue'
import ExternalLinkEtherscan from './external-link-etherscan.vue'
import PairMlLabs from './pair-ml-labs.vue'
import PairPriceChartLarge from './pair-price-chart-large.vue'
import PairPriceOhlcGraph from './pair-price-ohlc-graph.vue'
import PairTradeList from './pair-trade-list.vue'
import SimTestData from './sim-test-data.vue'
import TimeDisplay from './time-display.vue'
import preApeChecklist from './pre-ape-checklist.vue'
export default {
    name: 'view-pair',
    components:{
        ExternalLinkEtherscan,
        PairPriceChartLarge,
        BaseAmount,
        PairTradeList,
        TimeDisplay,
        ExternalLinkDextools,
        PairMlLabs,
        SimTestData,
        PairPriceOhlcGraph,
        preApeChecklist
    },
    emits:[
   ],
    data()
        {return {
        page_nav_index:0,
        pair:undefined,
        selected_trader_id:0,
        show_full_name :false,
        sim_dialog:false,
        feedback:'',
        snackbar:false,
        search:'',
        selected_sim:undefined,
        section_traders_trades_explore_info:undefined,
        tag_distribution_button_text : "Force Recalculate",
        loading:{
            page:false,
        },
        time_frame :{
            min:(Date.now()/1000) - 60*60*24,
            max:(Date.now()/1000)
        }
        
        }
    },
    props: { 
        address:{type:[String, Number],default:''},
        //data:{type:Object,default:Object},
        vlines:{type:Array,default:Array},
    },
    mounted(){
        this.refresh_data()
        },
    methods:{
        ...mapActions(['get_pair_by_address_page','request_simulated_trade', 'pair_get_section_traders_trades_explore_info']),
        disp_tokens(tokens, decimals){
            const whole_tokens =  tokens * (10**(-1*decimals))
            if(whole_tokens > 1e12){ return `${(whole_tokens/1e12).toFixed(1)} Tril`}
            if(whole_tokens > 1e9){ return `${(whole_tokens/1e9).toFixed(1)} Bil`}
            if(whole_tokens > 1e6){ return `${(whole_tokens/1e6).toFixed(1)} Mil`}
            if(whole_tokens > 1e3){ return `${(whole_tokens/1e3).toFixed(1)} K`}
            if(whole_tokens > 1){ return `${(whole_tokens/1).toFixed(1)} `}
            return `${whole_tokens.toFixed(6)}`
        },
        refresh_data(){
            this.loading.page = true
                const search_str = this.address == '' ? this.$route.params.pair_address : this.address
                this.get_pair_by_address_page({pair_address:search_str}).then( r =>{
                    this.loading.page = false
                    this.pair = r.data
                    this.do_pair_get_section_traders_trades_explore_info(false)
                    this.set_view()
                })
        },
        set_view(){
            const urlParams = new URLSearchParams(window.location.search);
            const view = urlParams.get('view');
            const views = ['chart','info','trades','safety','chatter']
            const index = views.indexOf(view)
            if (index > 0 ){ this.page_nav_index =  index }   
        },
        format_price_list_from_snap(reserves, pair){
            
            var price_list = []
            for (let i = 0; i < reserves.length; i++) {
                const r = reserves[i];
                price_list.push({
                    y:r.price * (pair.total_supply*(10**(-pair.decimals)) / 100), 
                    x:r.time_epoch
                })
            }
            return price_list
        },
        do_search(){
            console.log("doing search...");
            this.pair=undefined
            this.$route.params.pair_address = this.search
            window.location = `/pair/${this.search}`
   
        },
        epoch_to_string(epoch){
              const date = new Date(epoch * 1000);
              // Extract day, month, hours, and minutes from the date object
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0');
              const year = String(date.getFullYear())
              const hours = String(date.getHours()).padStart(2, '0');
              const minutes = String(date.getMinutes()).padStart(2, '0');
              return `${day}-${month} ${hours}:${minutes}`;
        },
        do_request_simulated_trade(){
            console.log("Requesting pair", this.pair);
            this.request_simulated_trade({pair_id:this.pair.id}).then(r=>{
                this.feedback = "Requested a new simulating. will auto refresh in 20 sec"
                this.snackbar = true
                setTimeout(this.refresh_data, 20000)
            })
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                console.log('Text copied to clipboard');
                })
                .catch(err => {
                console.error('Failed to copy text: ', err);
                });
        },
        formatEthereumAddress(ethAddress) {
            if (!ethAddress || !ethAddress.startsWith('0x') || ethAddress.length !== 42) {
                return null; // Invalid Ethereum address
            }
            const firstThree = ethAddress.slice(0, 6);
            const lastThree = ethAddress.slice(-3);
            const formattedAddress = `${firstThree}...${lastThree}`;
            return formattedAddress;
        },
        graph_view_change(view_change){
            this.time_frame.min = view_change.min
            this.time_frame.max = view_change.max
            this.do_pair_get_section_traders_trades_explore_info(false)
        },
        do_pair_get_section_traders_trades_explore_info(recalculate){
            if(this.pair.id == undefined){ return }
            this.tag_distribution_button_text = "loading"
            this.pair_get_section_traders_trades_explore_info({qs:{
                begin:this.time_frame.min,
                end:this.time_frame.max,
                pair_id:this.pair.id,
                recalculate:recalculate,
            },data:{}}).then((r)=>{

                this.section_traders_trades_explore_info = r.data
                this.tag_distribution_button_text = "Force Recalculate"
            })
        },
        str_to_color(tag){
            if (tag == undefined){ return 'hsla(1,100%,100%,1)'}
            function hashCode(str) {
                let hash = 0;
                for (let i = 0; i < str.length; i++) { hash = str.charCodeAt(i) + ((hash << 5) - hash); }
                return hash;
            }

            function stringToColor(str, minHue, maxHue, saturation, lightness, alpha) {
                const hue = ((hashCode(str) % (maxHue - minHue)) + minHue + 360) % 360;
                return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
            }

            return stringToColor(tag, 60, 180, 50, 80, 1);

        }


    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.pair-section {

    overflow-x:scroll;
}
td{
    white-space: nowrap;
}
.selected-cell{
    background:rgb(102, 83, 21)

}
.error-box{
    width:10px;
    height:10px;
    font-size:8px;
    text-align: center;
    line-height: 10px;
    background-color: #ff3e3e;
    margin:1px;
}
.high-tax{
    color:#ff3e3e;
}

.buy-cell{
    background-color: hsl(120, 17%, 44%);
}
.sell-cell{
    background-color: hsl(0, 17%, 44%);
}
td{
    text-align: center;
}

.info-table{
    th{
        color:gray;
    }
}
.content {
    height:calc(100vh - 100px);
    overflow: scroll;

}
a{
    text-decoration: none;
    color:rgb(0, 123, 255);
}
</style>


        // "buy_expected_tokens": 65878670.49157412,
        // "buy_actual_tokens":   138960.0730539091,