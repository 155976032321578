<template>
    <div>
   
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <h3>Trader {{traderId}}  ({{ disp_human_name() }}  )</h3>
                    <h5>Counts</h5>
                    <trader-counts-table :traderId="traderId"></trader-counts-table>
                </v-col>
                <v-col cols="12" md="12">
                    <h5>Scores</h5>
                    <trader-standard-score-sheet :traderId="traderId" ></trader-standard-score-sheet>
                    <v-btn class="ma-0"  elevation="2" small light block @click="go_to_page()" > go to page</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import TraderCountsTable from './trader-counts-table.vue';
import TraderStandardScoreSheet from './trader-standard-score-sheet.vue';


import names from '@/assets/names.json'

export default {
    name: 'trader-card-view',
    components:{
        TraderCountsTable,
        TraderStandardScoreSheet,
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        traderId:{type:[Number,String],default:Number},
    },
    mounted(){
        console.log("Loaded trader view",this.traderId);
    
    },
    methods:{
        ...mapActions([]),
        get_scores(){

        },
        disp_human_name(){
            // return 's'
            const tid = parseInt(this.traderId)
            const nrnames = names.length
            const first_index = tid%nrnames
            const second_index = (tid + 1000 ) %nrnames
            return `${names[first_index]} ${names[second_index]}`
        },
        go_to_page(){
            console.log("CHanging window location");
            window.location =  `/trader/${this.traderId}`
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">

</style>