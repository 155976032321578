<template>
    <div>
        <div v-if="data">
            <v-container fluid>
                <v-row>
                    <v-col cols="12" md="4" v-for=" (log,log_name) in data.logs" :key="log_name">
                        <h3>{{log_name}}</h3>
                        <pre>

                            {{log.action_data}}
                        </pre>
                        
                    </v-col>
         
                </v-row>
            </v-container>

        </div>
        <pre>
            {{data}}
        </pre>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'sim-test-data',
    components:{
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        //propName:{type:String,default:''},
        data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
pre{
    font-size:0.7em;
    overflow:scroll;
  
}
</style>