<template>
    <div>
        <!-- <h5>Select A Portfolio</h5> -->
        <v-autocomplete 
        v-model="selected_list" 
        :items="lists" 
     
        item-title="name"  item-text="name" return-object
        prepend-inner-icon="mdi-magnify"
        append-icon="mdi-plus"
        @click:append="create_list_dialog= !create_list_dialog"
        @change="$emit('list_selected', selected_list)"
        rounded
        outlined
        hide-details
        dense
        :loading="loading"
        color="white"
        :label="`Select your ${staticListType} Portfolio`"  >
            <template v-slot:selection="data">
                <v-list-item-title> {{data.item.name }}</v-list-item-title>
            </template>
            <template v-slot:item="data">
                <!-- <v-list-item-content>
                    {{data.item.name}}
                </v-list-item-content> -->
                <v-list-item-title> {{data.item.name }}</v-list-item-title>
                <v-list-item-subtitle> by {{data.item.owner.nickname}} </v-list-item-subtitle>

            </template>
        </v-autocomplete>


        <v-dialog v-model="create_list_dialog"  max-width="70vw" >
            <v-card>
                <v-sheet rounded="sm">
                    <my-list-add 
                    @list_added="create_list_dialog =  false;do_static_pair_list_actions_get_my_lists()"
                    :staticListType="staticListType">
                    </my-list-add>
                </v-sheet>
            </v-card>
        </v-dialog>
   
    </div>
</template>
<script>
// Begin Component Imports
import MyListAdd from '@/components/static_lists/my-list-add.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'my-list-selector',
    components:{
        MyListAdd
    },
    emits:[
        'list_selected'
    ],
    data(){return {
        lists:undefined,
        selected_list:[],
        create_list_dialog:false,
        loading:false,

        }
    },
    props: { 
        staticListType:{type:String,default:'pair'},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
            if (this.staticListType == 'pair'){ this.do_static_pair_list_actions_get_my_lists() }
            if (this.staticListType == 'trader'){ this.do_static_trader_list_actions_get_my_lists() }
    },
    methods:{
        ...mapActions(['static_pair_list_actions', 'static_trader_list_actions']),
        do_static_pair_list_actions_get_my_lists(){
            this.loading = true
            this.static_pair_list_actions({data:{},qs:{action:'get_my_lists'}}).then(r=> {
                this.lists = r.data;
                this.loading=false
                })
        },
        do_static_trader_list_actions_get_my_lists(){
            this.loading = true
            this.static_trader_list_actions({data:{},qs:{action:'get_my_lists',team_id:this.user_data.info.selected_team.id}}).then(r=> {
                this.lists = r.data
                this.loading=false
                })
        },

    },
    computed:{
        ...mapState(['user_data']),
    },
    watch:{
        'user_data.info.selected_team.id':(nv,ov)=>{
            if (this.staticListType == 'pair'){ this.do_static_pair_list_actions_get_my_lists() }
            if (this.staticListType == 'trader'){ this.do_static_trader_list_actions_get_my_lists() }
        }
    },
}
</script>
<style scoped lang="scss">
</style>