<template>
    <div>
        <div v-if="trader_counts">
            <table>
                <tr> <th>tk bought</th> <td>{{trader_counts.counts[traderId].tokens_bought}}</td> </tr>
                <tr> <th>tk sold</th> <td>{{trader_counts.counts[traderId].tokens_sold}}</td> </tr>
                <tr> <th>total trades</th> <td>{{trader_counts.counts[traderId].total_trades}}</td> </tr>
                <tr> <th>trades per tk bought</th> <td>{{trader_counts.counts[traderId].trades_per_btoken}}</td> </tr>
                <tr> <th>tk bought per tk sold</th> <td>{{trader_counts.counts[traderId].btokens_per_stokens}}</td> </tr>
            </table>

        </div>
        <v-btn v-else class="ma-0"  elevation="2" small block @click="do_get_trader_counts" > get trader counts</v-btn>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'trader-counts-table',
    components:{
    },
    emits:[
    ],
    data(){return {
        trader_counts:undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
        traderId:{type:Number,default:Number},
    },
    mounted(){
        this.do_get_trader_counts()
    
    },
    methods:{
        ...mapActions(['traders_calc_list_counts']),
                do_get_trader_counts(){
            this.traders_calc_list_counts({qs:{},data:{trader_ids:[this.traderId]}}).then((r)=>{
                this.trader_counts = r.data
            })

        },
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>