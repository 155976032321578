<template>
    <div>
        <a target="blank" :href="`https://www.dextools.io/app/en/ether/pair-explorer/${tokenAddress}`"> 
            <img class="mt-2" :height="height" :src="`${db_url}static/img/dextools_logo.png`" alt="" srcset="">

            <slot></slot>
        </a>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'external-link-dextools',
    components:{
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        tokenAddress:{type:String,default:''},
        height:{type:String,default:'20px'},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState(['db_url']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.slot-inner{

}
</style>