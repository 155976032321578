<template>
    <div>
        this is chartjs-trader-scores-scatter-plot todo: finish this
        <pre>
            {{tids}}
        </pre>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'chartjs-trader-scores-scatter-plot',
    components:{
    },
    emits:[
    ],
    data(){return {
        tids:[],
        score_metrics: [],
        x_axis:undefined,
        y_axis:undefined,
        dataset:undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        standardScores:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
        standardScores:{
            deep:true,
            handler: function(nv,ov){
            this.tids = Object.keys(this.standardScores)
        }
        }
    },
}
</script>
<style scoped lang="scss">
</style>