<template>
    <div>
        <v-sheet rounded="sm">
            <!-- {{xData}} {{yData}} -->
            <div id="line-chart"></div>
        </v-sheet>
 
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import Plotly from 'plotly.js-dist-min'


export default {
    name: 'plotly-2d-scatter',
    components:{
    },
    emits:[
    ],
    data(){return { 

    }
    },
    props: { 
        //x_data:{type:Object,default:Object},
        chartName:{type:String,default:''},
        xData:{type:Array,default:Array},
        yData:{type:Array,default:Array},
        metaData: {type:Object, default: () => {return {xAxisName:'',yAxisName:''}}}
    },
    mounted(){
    const trace = {
            x: this.xData, y: this.yData, 
            type: 'scatter', mode: 'markers', 
            line: {
            shape: 'spline',smoothing: 0.3,color: 'white', width: 2, dash: 'solid',       
            smoothingValues: [    0.5, 0.5, 0.5, 0.5, 0.5   ],
        },
      name: this.chartName,
    };

    const layout = {
        title: this.chartName,
        plot_bgcolor: 'transparent',
        paper_bgcolor: 'transparent',
        font: { color: 'white',  },

        xaxis: { title: this.metaData.xAxisName , gridcolor: '#ffffff41',},
        yaxis: { title: this.metaData.yAxisName, gridcolor: '#ffffff41', },
        shapes: [
            // {
            //     type: 'line',  x0: '2023-09-01', x1: '2023-09-01', y0: 102, y1: 105,       
            //     line: { color: 'red',   width: 2,       },
            // },
        ],
    };

    const chartContainer = document.getElementById('line-chart');
        Plotly.newPlot('line-chart', [trace], layout);
        
        chartContainer.on('plotly_click', (eventData) => {
            if (eventData.points.length > 0) {
                const clickedPoint = eventData.points[0];
                const xValue = clickedPoint.x;
                const yValue = clickedPoint.y;
                const text = clickedPoint.text;
                console.log(`Clicked Data Point: X=${xValue}, Y=${yValue}, Text="${text}"`);
            }
        });

    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
svg{
    background-color: red!important;
}
</style>