<template>
    <div>

        <v-container fluid>
            <v-row>
                <v-col sm="12" md="12" v-if="trade_metrics" >
                    <h3> Trade(r) Metrics </h3>
                    <table>
                        <tr>
                            <th>trades per trader</th>
                            <td> {{trade_metrics.trades_per_trader.toFixed(2)}} x </td>
                        </tr>
                        <tr>
                            <th>Average trade amount</th>
                            <td> {{trade_metrics.trades_per_trader.toFixed(2)}} x </td>
                        </tr>
                        <tr>
                            <th>Trade amount variance</th>
                            <td> {{trade_metrics.trades_per_trader.toFixed(2)}} x </td>
                        </tr>
                    </table>
                </v-col>
                <v-col sm="12" md="12">
                    <v-tabs centered color="white" >
                            <v-tab>
                                trades  
                                <span v-if="trades">  ( <span v-if="trader_mechanics.select_list.length" class="selected_text">  {{filtered_trades_by_traders_selected.length}} of </span> {{trades.length}})  </span> 
                                <sub  v-if="trader_mechanics.select_list.length" >  ({{(100*filtered_trades_by_traders_selected.length/ trades.length).toFixed(0)}} % )   </sub>
                            </v-tab>
                            <v-tab>
                                traders 
                                <span v-if="trader_id_list.length">  ( <span v-if="trader_mechanics.select_list.length" class="selected_text">  {{trader_mechanics.select_list.length}} of </span> {{trader_id_list.length}}) </span>
                            </v-tab>


                            <!-- trades  -->
                            <v-tab-item>
                                    <table v-if="trades">
                                        <tr>
                                            <th>date</th>
                                            <th>b/s</th>
                                            <th>amount</th>
                                            <th>wallet</th>
                                            <th>other</th>
                                        </tr>
                                        <tr v-for="t in filtered_trades_by_traders_selected" :key="t.id" :style="`color:${ t.direction == 'buy' ? 'green': 'red'}`">
                                            <td style="color:white"><time-display :time="t.time_epoch"></time-display></td>
                                            <td>{{t.direction}}</td>
                                            <td><base-amount :amount="t.amount_base"></base-amount></td>
                                            <td style="color:white" @click="trader_toggle_select(t.trader__id)">
                        
                                                <trader-link-to-page :traderId="t.trader__id"></trader-link-to-page>
                                            </td>
                                            <td> <external-link-etherscan :hash="t.hash"></external-link-etherscan></td>
                                        </tr>
                                    </table>
                            </v-tab-item>

                            <!-- traders  -->
                            <v-tab-item>
                                <!-- <v-btn class="ma-1"  elevation="2" small  @click="do_traders_calc_list_counts" > get trader counts</v-btn> -->

                                <!-- <trader-selector-on-standard-scores @selected_ids_changed="batch_trader_toggle" :traderIds="trader_id_list"></trader-selector-on-standard-scores> -->
                                
                             
                                <!-- <v-btn class="ma-1"  elevation="2" small  @click="do_traders_calc_list_counts" > calculate standard metrics for all traders </v-btn> -->

                                <v-btn class="ma-1"  elevation="2" small v-if="trader_mechanics.select_list.length" @click="dialog.add_traders_to_portfolio= !dialog.add_traders_to_portfolio" color="#6130715e" >  <v-icon class="ma-0"  small >mdi-plus</v-icon> add selected traders to portfolio </v-btn>
                                <v-btn class="ma-1"  elevation="2" small @click="dialog.explore_scores_and_export = true" >Explore Scores and export</v-btn>
                                <v-btn class="ma-1"  elevation="2" small @click="dialog.extract_traders_on_scores = true" >Extract Traders based on Scores (Batch Job)</v-btn>
                                <table>
                                    <tr>
                                        <td></td>
                                        <td>  </td>
                                        <th colspan="2"> On this pair </th>
                                      
                                        <th v-if="trader_data" colspan="5"> all time trader data</th>
                                    </tr>
                                    <tr v-if="trader_data">
                                        <th></th>
                                        <th> trader </th>
                                        <th @click="trader_mechanics.sort_by = 'amount'"> amount </th>
                                        <th @click="trader_mechanics.sort_by = 'trade_count'" > pair trades </th>
                                        <th @click="trader_mechanics.sort_by = 'total_trades'" > tot trades </th>
                                        <th @click="trader_mechanics.sort_by = 'tokens_bought'" > tokens (b/s) </th>
                                        <th @click="trader_mechanics.sort_by = 'btokens_per_stokens'" > buys per sell</th>
                                        <th @click="trader_mechanics.sort_by = 'trades_per_btoken'" > trades per tk</th>

                                    </tr>
                                    <tr v-for="t in trader_id_list" :key="t" :class="is_trader_selected(t) ? 'selected_cell' : '' ">
                                        <td @click="trader_toggle_select(t)" > + </td>
                                        <td><trader-link-to-page :traderId="t"></trader-link-to-page></td>
                                        <td> <base-amount :amount="traders[t].amount"></base-amount></td>
                                        <td>{{traders[t].trade_count}}</td>


                                        <td v-if="trader_data">  {{trader_data.counts[t].total_trades}} </td>
                                        <td v-if="trader_data"> 
                                            <span style="color:green">
                                                {{trader_data.counts[t].tokens_bought}}
                                            </span> / 
                                            <span style="color:red">
                                                {{trader_data.counts[t].tokens_sold}}
                                            </span>
                                        </td>
                                        <td v-if="trader_data"> {{trader_data.counts[t].btokens_per_stokens.toFixed(2)}}</td>
                                        <td v-if="trader_data"> {{trader_data.counts[t].trades_per_btoken.toFixed(2)}}</td>
                                    </tr>
                                </table>
                            </v-tab-item>
                    </v-tabs>
                </v-col>
                <v-col sm="12" md="12">
                    <div style="display:flex;justify-content:space-between;">

                       FROM <time-display :time="startEpoch"></time-display> to <time-display :time="endEpoch"></time-display>  
                    </div>

                    <v-btn class="ma-1"  block elevation="2" small @click="do_pair_list_trades" >  Get trades </v-btn>
                    
                </v-col>


                <v-dialog v-model="dialog.add_traders_to_portfolio"  max-width="70vw" >
                    <v-sheet rounded="sm">
                        <trader-static-list-add-ids @added="dialog.add_traders_to_portfolio = false " :selectedIds="trader_mechanics.select_list"></trader-static-list-add-ids>
                    </v-sheet>
                </v-dialog>

                <v-dialog v-model="dialog.extract_traders_on_scores" >
                    <v-sheet rounded="sm">
                        <traders-extract-from-pair-on-score-form :pairId="pairId" > </traders-extract-from-pair-on-score-form>
                    </v-sheet>
                </v-dialog>
                <v-dialog v-model="dialog.explore_scores_and_export" >
                    <v-sheet rounded="sm">
                        <traders-group-table :traderIds="trader_id_list" @traders_selected="(e)=>{trader_mechanics.select_list = e}" ></traders-group-table>
                    </v-sheet>
                </v-dialog>

            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
import BaseAmount from './base-amount.vue'
import ExternalLinkEtherscan from './external-link-etherscan.vue'
import TimeDisplay from './time-display.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
import TraderLinkToPage from './trader-link-to-page.vue'
import TraderSelectorOnStandardScores from './trader-selector-on-standard-scores.vue'
import TraderStaticListAddIds from './static_lists/trader-static-list-add-ids.vue'
import TradersExtractFromPairOnScoreForm from './traders-extract-from-pair-on-score-form.vue'
import TradersGroupTable from './traders-group-table.vue'
export default {
    name: 'pair-trade-list',
    components:{
        TimeDisplay,
        ExternalLinkEtherscan,
        BaseAmount,
        TraderLinkToPage  ,
        TraderSelectorOnStandardScores,
        TraderStaticListAddIds,
        TradersExtractFromPairOnScoreForm,
        TradersGroupTable
    },
    emits:[ ],
    data(){return {
        trades:undefined,

        trader_data:undefined,
        trade_metrics:undefined,
        tokens:{},
        traders:{},
        loading:{
            trade:false,
            traders:false,

        },
        trader_mechanics:{
            select_list: [],
            sort_by:'amount',
        },
        dialog:{
            add_traders_to_portfolio:false,
            extract_traders_on_scores:false,
            explore_scores_and_export:false,

        },

        }
    },
    props: { 
        //propName:{type:String,default:''},
        timeFrame:{type:Object,default:Object},
        //list:{type:Array,default:Array},
        pairId:{type:Number,default:Number},
        startEpoch:{type:Number,default:Number},
        endEpoch:{type:Number,default:Number},
    },
    mounted(){
        // this.do_traders_calc_list_counts()
    
    },
    methods:{
        ...mapActions(['traders_calc_list_counts','pair_list_trades']),
        do_pair_list_trades(){
            this.loading.trade =true
            this.pair_list_trades({qs:{pair_id:this.pairId, start_epoch:this.startEpoch, end_epoch:this.endEpoch},data:{}}).then((r)=>{
                this.trades = r.data.trades
                this.tokens = r.data.tokens
                this.traders = r.data.traders
                this.trade_metrics = r.data.metrics
                this.loading.trade =false
                this.do_traders_calc_list_counts()
            })
        },
        do_traders_calc_list_counts(){
            this.loading.traders = true
            this.traders_calc_list_counts({qs:{},data:{trader_ids:this.trader_id_list}}).then((r)=>{
                this.trader_data = r.data
                this.loading.traders = false
            })
        },
        is_trader_selected(trader_id){
            return this.trader_mechanics.select_list.includes(trader_id)
        },
        trader_toggle_select(trader_id){
            const trader_id_str = `${trader_id}`
            if ( this.is_trader_selected(trader_id_str)){ 
                this.trader_mechanics.select_list = this.trader_mechanics.select_list.filter((x)=> x != trader_id_str)
            }else{
                this.trader_mechanics.select_list.push(trader_id_str)
            }
        },
        batch_trader_toggle(tids){
            const trader_id_str = `${trader_id}`
            for (let i = 0; i < tids.length; i++) {
                const tid = tids[i];
                // this.trader_toggle_select(tid)
                if ( !this.is_trader_selected(tid)){ 
                    this.trader_mechanics.select_list.push(trader_id_str)
                }
            }



        },


    },
    computed:{
        ...mapState([]),
        trader_id_list(){
            let id_list =  Object.keys(this.traders)
            if(['amount','trade_count'].includes(this.trader_mechanics.sort_by)){
                id_list = id_list.sort((a,b)=> this.traders[b][this.trader_mechanics.sort_by] - this.traders[a][this.trader_mechanics.sort_by])
            }else{
                id_list = id_list.sort((a,b)=> this.trader_data.counts[b][this.trader_mechanics.sort_by] - this.trader_data.counts[a][this.trader_mechanics.sort_by])
                
            }
            return id_list
        },
        filtered_trades_by_traders_selected(){
            if (this.trader_mechanics.select_list.length == 0 ){ return this.trades }
            return  this.trades.filter( (tr)=>{
                return this.trader_mechanics.select_list.includes(tr.trader__id.toString())
            })
        }
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
td{
    text-align: center;
}
.selected_cell{
    background: #6130715e;
}
.selected_text{
    color:#b75ad65e;
}
</style>


